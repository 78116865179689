import config from '@/configs/timemanagement';
export default
    [

        {
            path: config.basepath + '/shops',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-shops-list',
                    component: () => import('@/components/timemanagement/shops/list/IndexShops'),
                    meta: { title: '店舗一覧', policy: { is_head: true, is_sp: false } },
                },
            ],
        },
        {
            path: config.basepath + '/shops/dailyreport',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-shops-dailyreport',
                    component: () => import('@/components/timemanagement/shops/dailyreport/IndexShopsDailyReport'),
                    meta: { title: '他店の状況', policy: { is_head: false, ref_other_daily_reports: "1", is_vmanage_use_only: true } },
                }
            ]
        },
    ]
