import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 新規作成
    create(apiconf, staff_id, area) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/create",
            query: { staff_id: staff_id, area: area },
        })
    },

    // 更新
    update(apiconf, staff_id, area) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/update",
            query: { staff_id: staff_id, area: area },
        })
    },

    // コード生成
    newCode(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "random/areacd/create",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // マネージャー紐づけ
    getAssociates(apiconf, staff_id, area_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/associates",
            query: { staff_id: staff_id, conditions: { area_id: area_id } },
        })
    },
    addAssociates(apiconf, staff_id, area_associates) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/event/add/associates",
            query: { staff_id: staff_id, area_associates: area_associates },
        })
    },
    removeAssociates(apiconf, staff_id, area_associates) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/event/rm/associates",
            query: { staff_id: staff_id, area_associates: area_associates },
        })
    },
    updateAssociate(apiconf, staff_id, area_associate) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "area/event/update/associate",
            query: { staff_id: staff_id, area_associates: area_associate },
        })
    },
}