import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    exportTask(apiconf, staff_id, shop_id, export_format) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/export",
            query: { 
                staff_id: staff_id,
                shop_id: shop_id,
                export_format:export_format,
            },
        })
    },

    // 新規作成
    create(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "task/create",
            formdata: formdata,
        })
    },

    // 更新
    update(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/update",
            query: { staff_id: staff_id, task: task },
        })
    },

    // タグ
    getTagList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/tags/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    createTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/create/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    addTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/add/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },
    removeTag(apiconf, staff_id, tag) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/remove/tag",
            query: { staff_id: staff_id, tag: tag },
        })
    },

    // オプション一覧
    getOptionList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/options/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    createOption(apiconf, staff_id, option) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/create/option",
            query: { staff_id: staff_id, option: option },
        })
    },
    addOption(apiconf, staff_id, option) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/add/option",
            query: { staff_id: staff_id, option: option },
        })
    },
    removeOption(apiconf, staff_id, option) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/remove/option",
            query: { staff_id: staff_id, option: option },
        })
    },

    // ファイル
    addAttachment(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "task/event/add/attachment",
            formdata: formdata,
        })
    },
    removeAttachment(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/rm/attachment",
            query: { staff_id: staff_id, task: task },
        })
    },
    previewFile(apiconf, staff_id, task_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/task/file",
            query: {
                staff_id: staff_id,
                task_id: task_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    // ファイルキャプション
    saveAttachmentCaption(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/update/caption",
            query: { staff_id: staff_id, task: task },
        })
    },

    // サブタスク
    getListSub(apiconf, staff_id, task_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/list",
            query: { staff_id: staff_id, task_id: task_id, page: page },
        })
    },
    createSub(apiconf, staff_id, task_sub, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task_sub: task_sub,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "tasksub/create",
            formdata: formdata,
        })
    },
    updateSub(apiconf, staff_id, task_sub) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/update",
            query: { staff_id: staff_id, task_sub: task_sub },
        })
    },
    addSub(apiconf, staff_id, task_sub) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/add/tasksub",
            query: { staff_id: staff_id, task_sub: task_sub },
        })
    },
    removeSub(apiconf, staff_id, task_sub) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/event/remove/tasksub",
            query: { staff_id: staff_id, task_sub: task_sub },
        })
    },
    getOptionListSub(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/options/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    createOptionSub(apiconf, staff_id, option) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/create/option",
            query: { staff_id: staff_id, option: option },
        })
    },
    addOptionSub(apiconf, staff_id, option) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/add/option",
            query: { staff_id: staff_id, option: option },
        })
    },
    removeOptionSub(apiconf, staff_id, option) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/remove/option",
            query: { staff_id: staff_id, option: option },
        })
    },
    addAttachmentSub(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "tasksub/event/add/attachment",
            formdata: formdata,
        })
    },
    removeAttachmentSub(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/rm/attachment",
            query: { staff_id: staff_id, task: task },
        })
    },
    previewFileSub(apiconf, staff_id, task_sub_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/tasksub/file",
            query: {
                staff_id: staff_id,
                task_sub_id: task_sub_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    saveAttachmentCaptionSub(apiconf, staff_id, tasksub) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/update/caption",
            query: { staff_id: staff_id, tasksub: tasksub },
        })
    },

    createDishSub(apiconf, staff_id, dish) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/create/dish",
            query: { staff_id: staff_id, dish: dish },
        })
    },
    updateDishSub(apiconf, staff_id, dish) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tasksub/event/update/dish",
            query: { staff_id: staff_id, dish: dish },
        })
    },

    // タスク使用中スケジュール取得
    getTaskInUse(apiconf, staff_id, task_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/inused",
            query: { staff_id: staff_id, task: { task_id: task_id } },
        })
    },

    // （My店舗）一覧取得
    searchMyShop(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/task/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // （My店舗）新規作成
    createMyShop(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "myshop/task/create",
            formdata: formdata,
        })
    },

    // 日報
    listDailyReport(apiconf, staff_id, daily_report_ids) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dailyreport/list",
            query: { staff_id: staff_id, report: { daily_report_ids: daily_report_ids } },
        })
    },
    createDailyReport(apiconf, staff_id, report) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dailyreport/create",
            query: { staff_id: staff_id, report: report },
        })
    },
    updateDailyReport(apiconf, staff_id, report) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dailyreport/update",
            query: { staff_id: staff_id, report: report },
        })
    },
    saveDailyReportItems(apiconf, staff_id, report_items) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dailyreport/items/upsert",
            query: { staff_id: staff_id, report_items: report_items },
        })
    },
}
