export default {
    cognitoBaseURL: "https://auth.portal.infomart.co.jp",
    region: "",
    pool_id: "ap-northeast-1_BZALTERzr",
    provider: {
        external: {
            client_id: "7s0035jg5a82b94enb5iicq03b",
            client_secret: "tr12j7lcgj2oidlv211m45h2595f9ijg2lp2bicgrkrm0r3on9",
        },
        app: {
            client_id: "5kdckk7fui2selqm5oo2b2ulhc",
            client_secret: "NULL",
        },
    }
}