<template>
  <header class="header">
    <div class="toolbar">
      <div class="toolbar-inner">
        <div class="toolbar-logo">
          <div class="toolbar-logo-img p-relative">
            <img :src="getLogoVManage()" width="110" />
            <button class="basic-table-menu-toggle logo-toggle">
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </button>
            <div class="menu-drawer logo-toggle-menu">
              <div class="menu-drawer-item">
                <button @click="redirectPortal(this)">
                  <img :src="getLogoFPortal()" width="110" />
                </button>
              </div>
              <div class="menu-drawer-item">
                <button>
                  <img :src="getLogoVManage()" width="110" />
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="toolbar-date-time ml-2 font-weight-bold">
            {{ nowdatetime }}
          </div> -->
        </div>
        <div class="toolbar-menu toolbar-nav">
          <div class="toolbar-icons">
            <div v-for="(menu, i) in getTimemanagementHeaderIconLinks()" :key="i" class="toolbar-icon">
              <template v-if="!menu.sublinks">
                <button :class="{ active: menu.active }" @click="pageMove(menu)">
                  <i aria-hidden="true" :class="'mdi ' + menu.icon"></i>
                  <!-- 通知バッヂ -->
                  <div v-if="menu.is_newer" class="notification-badge"></div>
                  <div v-if="menu.name == 'チャット' && unread_count > 0" class="notification-badge">
                  </div>
                </button>
              </template>
              <template v-else>
                <div class="toolbar-various-settings toolbar-icons toolbar-icon-has-sublinks p-relative">
                  <button :class="{ active: menu.active }">
                    <i aria-hidden="true" :class="'mdi ' + menu.icon"></i>
                  </button>
                  <ul class="toolbar-nav-child">
                    <template v-for="(sublink, j) in menu.sublinks" :key="j">
                      <li>
                        <a @click="pageMove(sublink)">{{ sublink.name }}</a>
                      </li>
                    </template>
                  </ul>
                </div>
                </template>
            </div>
          </div>
        </div>
        <div class="toolbar-account">
          <div class="toolbar-account-btn" :class="{ shareActive: share_mode }">
            <span class="toolbar-store-name">{{
              share_mode ? viewCompanyOrShopName() : viewCompanyOrShopName()
            }}</span>
            <div v-if="share_mode" class="share-active-avatar">
              <v-icon color="#103087">mdi-account-multiple</v-icon>
            </div>

            <v-avatar v-else-if="url_avatar" size="40">
              <img :src="getAccountAvatarUrl()" @error="url_avatar = false" cover />
            </v-avatar>
            <div v-else class="v-avatar">
              <i class="mdi mdi-account"></i>
            </div>
          </div>
          <div class="toolbar-account-detail" v-if="!change_shop_modal">
            <div class="toolbar-switch" v-if="isEnabledShopShareMode()">
              <div class="toolbar-switch-title">
                <v-icon color="#103087">mdi-account-multiple</v-icon>
                店舗共有モード
              </div>
              <div class="toolbar-switch-switch" v-if="!isUserShopAccount()" >
                <div class="d-flex align-center">
                  <input type="checkbox" class="switch-checkbox" id="switch1" v-model="share_mode"
                    @change="changeShopShareMode(this, share_mode)" />
                  <div class="switch-text switch-text-off mr-2">OFF</div>
                  <label class="switch-label" for="switch1"></label>
                  <div class="switch-text switch-text-on ml-2">ON</div>
                </div>
              </div>
            </div>
            <div class="toolbar-account-name">
              <div class="toolbar-account-icon">
                <div class="v-avatar" v-if="url_avatar">
                  <v-avatar size="40">
                    <v-img v-if="url_avatar" :src="getAccountAvatarUrl()" @error="url_avatar = false" cover />
                  </v-avatar>
                </div>
                <div class="v-avatar" v-else>
                  <i class="mdi mdi-account"></i>
                </div>
              </div>
              <div class="font-weight-bold mt-2">
                {{ parents.userinfo.staff_name }}
              </div>
            </div>
            <div class="toolbar-account-place">
              <div class="toolbar-account-company">
                {{ parents.userinfo.company_full_name }}<br />
                {{ parents.userinfo.brand_name }}
              </div>
              <div class="toolbar-account-store font-weight-bold">
                {{ parents.userinfo.shop_name }}
              </div>
              <div class="toolbar-account-role">
                {{ getUserRoleName() }}
              </div>
            </div>
            <div class="toolbar-account-change" v-if="!isUserShopAccount()">
              <button @click="viewChangeShop()">
                <i class="mdi mdi-cached"></i>
                店舗切り替え
              </button>
            </div>
            <div class="toolbar-account-reload">
              <button @click="pageMove('/auth/getuserinfo')">
                最新の情報を取得
              </button>
            </div>
            <div class="toolbar-account-logout">
              <button @click="pageMove('/auth/signout')">サインアウト</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChangeShopModal v-if="change_shop_modal" :close="changeShop" />
    <ConfirmModal v-if="confirm_modal" title="編集を中断します。" message="編集を中断します。よろしいですか？" :close="execMove" />
    <ExecutingOverlay ref="execute" />
  </header>
</template>

<script>
import ChangeShopModal from "@/components/common/change_shop/PcChangeShopModal";
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
import ConfirmModal from "@/components/common/ConfirmModal";
export default {
  props: ["parents"],
  components: { ChangeShopModal, ExecutingOverlay, ConfirmModal },
  data: () => ({
    url_avatar: true,

    change_service: false,
    change_shop_modal: false,
    confirm_modal: false,

    share_mode: false,

    unread_count: 0,
    icon_name: 'チャット',
  }),
  setup() {
    const { debugLog } = require('@/helpers/common/datadog')
    const { getUserRoleName, getAccountAvatarUrl, isShopShareMode, isEnabledShopShareMode, isUserShopAccount } = require('@/helpers/auth/auth_util')
    const { getTimemanagementHeaderIconLinks, redirectPortal, changeShopShareMode } = require('@/helpers/timemng/timemng_util')
    const { pageMove, getLogoFPortal, openExternalWindow, getLogoVManage, getChatUnreadCount } = require('@/helpers/common/common_util')
    return {
      debugLog,
      getUserRoleName, getAccountAvatarUrl, isShopShareMode, isEnabledShopShareMode, isUserShopAccount,
      getTimemanagementHeaderIconLinks, redirectPortal, changeShopShareMode,
      pageMove, getLogoFPortal, openExternalWindow, getLogoVManage, getChatUnreadCount,
    }
  },
  async created() {
    this.share_mode = this.isShopShareMode();
    this.unread_count = await this.getChatUnreadCount()
  },

  methods: {
    viewCompanyOrShopName() {
      return this.parents.userinfo.is_head_shop
        ? this.parents.userinfo.company_name
        : this.parents.userinfo.shop_name;
    },

    checkHeaderLink(link) {
      if (link.external) {
        this.openExternalWindow(link.external);
      } else {
        this.pageMove(link.to);
      }
    },

    viewChangeShop() {
      this.change_shop_modal = true;
    },
    changeShop() {
      this.change_shop_modal = false;
    },

    // 遷移確認
    confirmMove(event) {
      if (this.parents.is_move_confirm) {
        this.save_event = event;
        this.confirm_modal = true;
        return true;
      }
      return false;
    },
    execMove(is) {
      // eslint-disable-line
      if (is) {
        // eslint-disable-line
      }
      this.confirm_modal = false;
    },
  },
  // computed: {
  //   nowdatetime() {
  //     return this.formatDateStr(this.clock, "MM月DD日（W）hh:mm");
  //   },
  // },
  // unmounted() {
  //   if (this.clock_timeout) clearTimeout(this.clock_timeout);
  // },
};
</script>

<style scoped src="./pc_header.css"></style>