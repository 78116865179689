import config from '@/configs/timemanagement';
export default
    [
        {
            path: config.basepath + '/menus',
            name: 'timemanagement-menus',
            component: () => import('@/components/timemanagement/others/menus/IndexMenus'),
            meta: { title: 'メニュー' },
        },

        {
            path:  config.basepath + '/help/contact',
            name: 'timemanagement-contact',
            component: () => import('@/components/timemanagement/others/contact/IndexContact'),
            meta: { title: 'お問い合わせフォーム' },
        },
        {
            path:  config.basepath + '/help/contact/complete',
            name: 'timemanagement-contact-complete',
            component: () => import('@/components/timemanagement/others/contact/IndexContactComplete'),
            meta: { title: 'お問い合わせ完了' },
        },

        {
            path: config.basepath + '/settings/alert',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-settings-alert',
                    component: () => import('@/components/timemanagement/others/settings/alert/IndexSettingsAlert'),
                    meta: { title: '通知設定', icon: "mdi-cog", policy: { is_head: false, is_app: true } },
                },
            ]
        },
        {
            path: config.basepath + '/settings/company',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-settings-company',
                    component: () => import('@/components/timemanagement/others/settings/company/IndexSettingsCompany'),
                    meta: { title: '利用設定', policy: { function_cd: "task_schedule_ui_ver", crud: ['c', 'r', 'u', 'd'], is_head: true, is_sp: false } },
                },
            ]
        },        
        {
            path: config.basepath + '/settings/employee',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-settings-employee',
                    component: () => import('@/components/timemanagement/others/settings/employee/IndexSettingsEmployee'),
                    meta: { title: '従業員表示設定', icon: "mdi-cog", policy: { is_head: false, is_staff: false, disabled_sharemode: true } },
                },
            ]
        },
        {
            path: config.basepath + '/iplimited',
            component: () => import('@/components/timemanagement/Index'),
            children: [{

                path: '',
                name: 'timemanagement-iplimited',
                component: () => import('@/components/timemanagement/others/iplimited/IndexIpAddressLimited'),
                meta: { title: 'IPアドレス利用制限' },
            }],
        },
    ]


