export default
    [
        {
            path: '/chat',
            component: () => import('@/components/common/IndexCommon'),
            children: [
                {
                    path: '',
                    name: 'chat',
                    component: () => import('@/components/common/chat/IndexChat'),
                    meta: { title: 'チャット', icon: "mdi-chat", policy: { function_cd: "chat", crud: ['r'] } },
                },
                {
                    path: ':room',
                    name: 'chat_room',
                    component: () => import('@/components/common/chat/IndexChat'),
                    meta: { title: 'チャット', icon: "mdi-chat", policy: { function_cd: "chat", crud: ['r'] } },
                },
            ]
        },
    ]


