import config from "@/configs/auth";
import congito_config from "@/configs/congito"
import storeAuth from "@/helpers/store/storeAuthHelper"
import storeActivate from "@/helpers/store/storeActivateHelper"
import auth_api from "@/helpers/auth/auth_api";
import common_api from '@/helpers/common/common_api'
import { isServiceTimemng } from "@/helpers/environment";
import { errorLog, debugLog, setDDRumUser, setDDRumContextProperty } from "@/helpers/common/datadog"
import { copyJson, isShopHead, getRouteName, routerPush, getCompanyFormName, generateRandamString } from "@/helpers/common/common_util";
import { getRoleList, getRoleNameByRoles } from "@/helpers/common/role";
import { reloadCompanySpec, reloadAccountSpec } from "@/helpers/auth/spec";
import { cognitoExternalOAuthToken } from "@/helpers/common/cognito";
import { isEnvProd } from "@/helpers/environment"
import { paginationLoad, apiParallelCall } from '@/helpers/api_common'

// サインイン
export function getSigninUrl() {
  return "/auth/signin"
}
// サインアウト
export function getSignoutUrl() {
  return "/auth/signout";
}
export function redirectSignin() {
  window.location = getSigninUrl();
}
export function redirectSignout() {
  window.location = getSignoutUrl();
}

// サインイン後遷移
export async function afterSinginMove() {
  if (storeActivate.storeGetActivateInviteCode()) {
    return routerPush("/setup/invite");
  } else {
    if (checkAccountStatus()) {
      return routerPush("/");
    }
  }
}

// プロファイルエラーチェック
export function checkProfileError(errmsg, account, associate) {
  if (errmsg) {
    if (errmsg == 'account not found.') {
      return "/setup/account"
    } else {
      return getSignoutUrl()
    }
  } else if (account && !associate) {
    // アカウントはあるけど、企業がない場合もアカウント初期画面へ
    return "/setup/account"
  } else if (Object.keys(associate.staff).length == 0) {
    return "/noshop"
  }
  return null
}

// アカウント状態チェック
export function checkAccountStatus() {
  // プロファイル状態取得
  const err_route = checkProfileError(
    storeAuth.storeGetProfileError(),
    storeAuth.storeGetAccount(),
    storeAuth.storeGetSelectedAssociate()
  )
  if (err_route) {
    routerPush(err_route);
    return
  }

  // TODO:店舗IDの場合、店舗アカウント選択へ

  return true
}

// Emailサインイン
export async function authSigninEmail(email, password) {
  // Cognitoサインイン
  const result = await auth_api.apiAuthSignin(email, password)
  storeAuth.storeSetCustomClaims(result.custom_claims)
  storeAuth.storeSetTokenRefreshRequired(result.token_refresh_required)
  return {
    type: "email",
    email: email,
    password: password,
    id_token: result.AuthenticationResult.IdToken,
    access_token: result.AuthenticationResult.AccessToken,
    refresh_token: result.AuthenticationResult.RefreshToken,
    expires_in: result.AuthenticationResult.ExpiresIn
  }
}
export async function initOAuthEmail(oauth) {
  // 情報保持（サインイン状態）
  await storeAuth.storeSetSigninInfo({ type: oauth.type, id: oauth.email })
  await storeAuth.storeSetToken({
    id_token: oauth.id_token,
    access_token: oauth.access_token,
    refresh_token: oauth.refresh_token,
    expires_in: oauth.expires_in
  });
  // ユーザ情報再読み込み
  await reloadProfile()
}

// 外部認証コード確認
export async function authSigninExternalConfirm(code) {
  let result = null
  try {
    result = await cognitoExternalOAuthToken(code)
  } catch (e) {
    await auth_api.apiAuthExternalSignin("1")
    throw e
  }
  return {
    type: storeAuth.storeGetPrevSigninType() ?? "external",
    code: code,
    id_token: result.id_token,
    access_token: result.access_token,
    refresh_token: result.refresh_token,
    expires_in: result.expires_in
  }
}
export async function initOAuthExternal(oauth) {
  // 情報保持（サインイン状態）
  await storeAuth.storeSetSigninInfo({ type: oauth.type, code: oauth.code })
  const result = await auth_api.apiAuthExternalSignin("0", oauth.id_token, oauth.access_token)
  storeAuth.storeSetCustomClaims(result.custom_claims)
  storeAuth.storeSetTokenRefreshRequired(result.token_refresh_required)
  await storeAuth.storeSetToken({
    id_token: oauth.id_token,
    access_token: oauth.access_token,
    refresh_token: oauth.refresh_token,
    expires_in: oauth.expires_in
  });

  // ユーザ情報再読み込み
  await reloadProfile()
}

// ユーザ情報再読み込み
export async function reloadProfile() {
  try {
    // プロファイル取得
    let profile = await auth_api.apiGetUserProfile()
    if (profile.error) {
      storeAuth.storeSetProfileError(profile.error)
      return false
    }

    // 企業・ブランドプロファイル取得
    await readCompanyProfile(profile.data.profile);

    // ユーザプロファイル保持
    await storeAuth.storeSetProfile(getUserProfileData(profile.data.profile));

    // 表示店舗設定
    await setDisplayShop()

    if (storeAuth.storeGetSelectedStaffId()) {
      // ロール読み込み
      if (!isShopShareMode()) {
        let roles = await getRoleList()
        storeAuth.storeSetRoles(roles)

      }
      // 選択店舗情報再設定
      await resetSelectedShopInfo()
    }

    // 企業・アカウント設定読み込み
    await apiParallelCall({
      company: { method: reloadCompanySpec },
      account: { method: reloadAccountSpec },
    });

    // リフレッシュトークン要求時
    if (storeAuth.storeGetTokenRefreshRequired()) {
      const token = await auth_api.apiTokenRefresh()
      if (!token.error) {
        storeAuth.storeSetToken({
          id_token: token.AuthenticationResult.IdToken,
          access_token: token.AuthenticationResult.AccessToken,
          refresh_token: storeAuth.storeGetRefreshToken(),
          expires_in: token.AuthenticationResult.ExpiresIn
        })
        storeAuth.storeSetTokenRefreshRequired(true)
      }
    }
    return true
  } catch (err) {
    errorLog(getRouteName(), "reloadProfile", err);
    throw err
  }
}

// 店舗共有モード
export function isShopShareMode() {
  if (isServiceTimemng()) {
    if(isUserShopAccount()) return true;  // 店舗アカウント時
    if (isEnabledShopShareMode()) {
      let share_mode = storeAuth.storeGetShopShareMode(storeAuth.storeGetKeyAsSelectedStaff())
      return share_mode != undefined && share_mode != null
    }
    return false
  } else {
    // ポータルでは使わない
    return false
  }
}
export function isEnabledShopShareMode() {
  // 本部以外なら常に共有モード可能に
  return !isUserHeadShop()
}

// 企業プロファイル取得
export async function readCompanyProfile(profile) {
  if (profile.accounts && profile.accounts.associates) {
    await apiParallelCall({
      company: { method: _readCompanyProfile, params: [profile] },
      brand: { method: _readBrandProfile, params: [profile] },
    });
  }
}
async function _readCompanyProfile(profile) {
  if (profile.accounts && profile.accounts.associates) {
    let staff_ids = []
    profile.accounts.associates.forEach(_associate => {
      if (_associate.staff) {
        staff_ids.push(_associate.staff[0].staff_id)
      }
    });
    if (staff_ids.length > 0) {
      const comapnies = await auth_api.apiGetUserCompanyInfos(staff_ids)
      for (const company of comapnies.data) {
        let associate = profile.accounts.associates.find(v => v.company_id == company.company_id)
        if (associate) {
          Object.assign(associate, company)
        }
      }
    }
  }
}

// ブランドプロファイル取得
async function _readBrandProfile(profile) {
  if (profile.accounts && profile.accounts.associates) {
    let brands = []
    profile.accounts.associates.forEach(_associate => {
      if (_associate.staff) {
        _associate.staff.forEach(_staff => {
          if (_staff.shop_info && _staff.shop_info.brand) {
            _staff.shop_info.brand.forEach(_brand => {
              if (!brands.find(v => v.brand_id == _brand.brand_id)) {
                let brand = copyJson(_brand)
                brand.staff_id = _staff.staff_id
                brands.push(brand)
              }
            })
          }
        })
      }
    });
    if (brands.length > 0) {
      let staff_ids = []
      brands.forEach(brand => {
        if (!staff_ids.find(v => v == brand.staff_id)) {
          staff_ids.push(brand.staff_id)
        }
      })
      const results = await auth_api.apiGetUserBrandInfos(staff_ids)
      profile.accounts.associates.forEach(_associate => {
        if (_associate.staff) {
          _associate.staff.forEach(_staff => {
            if (_staff.shop_info && _staff.shop_info.brand) {
              _staff.shop_info.brand.forEach(_brand => {
                let brand = results.find(v => v.brand_id == _brand.brand_id)
                Object.assign(_brand, brand)
              })
            }
          })
        }
      });
    }
  }
}

// プロファイルデータ成形
export function getUserProfileData(profile) {
  let data = {}
  let account = copyJson(profile.accounts);
  delete account.associates
  data.account = account;
  let associates = {}
  if (profile.accounts.associates) {
    profile.accounts.associates.forEach(_associate => {
      let associate = copyJson(_associate);
      associate.brands = {}
      associate.head_shop = null;
      let staff = {}
      if (associate.staff) {
        associate.staff.forEach(_staff => {
          let shops = {}
          if (!_staff.shop_id) {
            // 店舗情報が無い場合は削除店舗扱い
            return
          }
          if (_staff.shop_info) {
            shops[_staff.shop_info.shop_id] = _staff.shop_info
          }
          _staff.shops = shops
          staff[_staff.staff_id] = _staff
          if (isShopHead(_staff.shop_info)) {
            associate.head_shop = {
              staff_id: _staff.staff_id,
              shop_id: _staff.shop_info.shop_id,
              shop_name: _staff.shop_info.shop_name,
            }
          } else {
            if (_staff.shop_info.brand && _staff.shop_info.brand.length > 0) {
              _staff.shop_info.brand.forEach(brand => {
                if (!associate.brands[brand.brand_id]) {
                  associate.brands[brand.brand_id] = { name: brand.brand_name, shops: [] }
                }
                associate.brands[brand.brand_id].shops.push({
                  staff_id: _staff.staff_id,
                  shop_id: _staff.shop_info.shop_id,
                  shop_name: _staff.shop_info.shop_name
                })
              })
            }
          }
        })
      }
      associate.staff = staff
      associates[associate.associate_id] = associate
    });
  }
  data.associates = associates;
  return data
}

// 表示店舗設定
export async function setDisplayShop() {
  return storeAuth.storeGetSelectedAssociate() ? await setAccountSelectedShop() : await setInitAccountSelectedShop()

  // 利用中ユーザ設定
  async function setAccountSelectedShop() {
    // 選択中店舗が無い場合
    if (!storeAuth.storeGetSelectedStaff()) {
      let associate = storeAuth.storeGetSelectedAssociate()
      let staff_ids = Object.keys(associate.staff)
      // 選択中企業に店舗が無くなっている場合
      if (staff_ids.length == 0) {
        let associates = storeAuth.storeGetAssociates()
        for (let associate_id of Object.keys(associates)) {
          associate = associates[associate_id]
          staff_ids = Object.keys(associate.staff)
          if (staff_ids.length > 0) break
        }
      }

      if (staff_ids.length > 0) {
        debugLog("associate", associate)
        debugLog("staff_ids", staff_ids)
        // 本部優先で存在する店舗に切り替え
        let staff = associate.head_shop ? associate.staff[associate.head_shop.staff_id] : associate.staff[staff_ids[0]]
        debugLog("staff", staff)
        const associate_id = associate.associate_id;
        const company_id = associate.company_id;
        const company_group_id = associate.company_group_id;
        const staff_id = staff.staff_id
        const shop_id = staff.shop_id
        let brand_id = null
        if (staff.shop_info.brand && staff.shop_info.brand.length > 0) {
          brand_id = staff.shop_info.brand[0].brand_id
        }
        storeAuth.storeSetAccountSelectedShop(associate_id, staff_id, shop_id, company_group_id, company_id, brand_id)
      }
    }
  }

  // 初回ユーザ用設定
  async function setInitAccountSelectedShop() {
    return new Promise(function (resolve) {
      let associates = storeAuth.storeGetAssociates();
      if (associates && Object.keys(associates).length > 0) {
        const associate_id = Object.keys(associates)[0];
        const associate = associates[associate_id];
        let company_id = associate.company_id;
        let company_group_id = associate.company_group_id;
        let staff_id = ""
        let shop_id = ""
        let brand_id = ""
        if (Object.keys(associate.staff).length > 0) {
          staff_id = Object.keys(associate.staff)[0];
          const staff = associate.staff[staff_id];
          shop_id = staff.shop_id;
          if (staff.shop_info.brand && staff.shop_info.brand.length > 0) {
            brand_id = staff.shop_info.brand[0].brand_id
          }
        }
        storeAuth.storeSetAccountSelectedShop(associate_id, staff_id, shop_id, company_group_id, company_id, brand_id)
      }
      resolve()
    });
  }
}

// 選択店舗情報再設定
export async function resetSelectedShopInfo() {
  const staff_id = storeAuth.storeGetSelectedStaffId(true)
  if (staff_id) {
    // 共有ユーザ情報
    if (!storeAuth.storeGetShopStaffShareUser()) {
      let share_user = null
      try {
        const groups = await auth_api.apiGetUserStaffGroup(staff_id, {
          include_self: "true",
          staff_group_only: "true",
          with_roles: "true",
        })
        share_user = groups.find(v => v.task_sharering == 0)
      } catch (err) {
        errorLog(getRouteName(), "resetSelectedShopInfo:shareuser", err);
      }
      storeAuth.storeSetShopStaffShareUser(staff_id, share_user)
    }

    // ロール情報
    let roles = []
    try {
      roles = await auth_api.apiGetUserStaffRoles(staff_id)
    } catch (err) {
      errorLog(getRouteName(), "resetSelectedShopInfo:roles", err);
    }
    storeAuth.storeSetShopStaffRoles(staff_id, roles)

    // スタッフグループ情報
    let staff_group = []
    try {
      const results = await common_api.apiStaffGroupGetOwnList()
      if (results.data.length > 0) {
        staff_group = results.data
      }
    } catch (err) {
      errorLog(getRouteName(), "resetSelectedShopInfo:staff_group", err);
    }
    storeAuth.storeSetStaffGroup(staff_group)
  }
}

// スタッフグループSSO取得
export async function readStaffGroupSso() {
  try {
    const results = await common_api.apiStaffGroupGetOwnList()
    return results.data.length > 0 ? results.data[0].sso[0] : null
  } catch {
    return null
  }
}
export function getSelectedStaffGroupSso() {
  const staff_group = storeAuth.storeGetStaffGroup()
  if (staff_group) {
    // 複数SSO設定がある場合は先勝ち
    const group = staff_group.find(v => v.sso && v.sso.length > 0)
    if (group) {
      return group.sso
    }
  }
  return null
}

// ユーザアバターURL
export function getAccountAvatarUrl() {
  return auth_api.apiAccountGetAvatarUrl(storeAuth.storeGetAccountId())
}

// ユーザ情報取得
export function getUserInfo() {
  try {
    setDDRumUser(storeAuth.storeGetAccountId())
    setDDRumContextProperty('associate_id', storeAuth.storeGetSelectedAssociateId())
    setDDRumContextProperty('proxy_signin', isProxySigninAccount())
  } catch { } // eslint-disable-line
  return {
    signin_info: storeAuth.storeGetSigninInfo(),
    company_name: getUserCompanyName(),
    company_full_name: getUserCompanyFullName(),
    brand_name: getUserBrandName(),
    shop_name: getUserShopName(),
    staff_name: getUserName(),
    avatar: getUserAvatar(),
    is_head_shop: isUserHeadShop(),
    // user_role: getTimemanagementRoleCd(),
  }
}

// 企業名取得
export function getUserCompanyName(company_only = false) {
  if (!company_only) {
    const fc_owner = storeAuth.storeGetKeyAsSelectedFranchiseOwner()
    if (fc_owner) return fc_owner.brand_name + "（FC）"
  }
  return storeAuth.storeGetSelectedAssociate("company_name");
}
export function getUserCompanyFullName(company_only = false) {
  if (!company_only) {
    const fc_owner = storeAuth.storeGetKeyAsSelectedFranchiseOwner()
    if (fc_owner) return fc_owner.brand_name + "（FC）"
  }
  const associate = storeAuth.storeGetSelectedAssociate()
  if (associate) {
    const form_name = getCompanyFormName(associate)
    if (associate.form_name_position === 0) {
      return form_name + associate.company_name
    } else {
      return associate.company_name + form_name
    }
  }
}

// 店舗名取得
export function getUserShopName() {
  let shop = storeAuth.storeGetSelectedShopInfo()
  return shop ? shop.shop_name : "";
}

// ブランド名取得
export function getUserBrandName() {
  const brand_id = storeAuth.storeGetKeyAsSelectedBrand()
  if (brand_id) {
    const brand = storeAuth.storeGetSelectedAssociate("brands")[brand_id]
    return brand ? brand.name : null
  }
  return null;
}

// ロール名
export function getUserRoleName() {
  return getRoleNameByRoles(storeAuth.storeGetSelectedRoles())
}

// 本店判定
export function isUserHeadShop() {
  return isShopHead(storeAuth.storeGetSelectedShopInfo())
}

// 店舗アカウント判定
export function isUserShopAccount() {
  const info = storeAuth.storeGetSigninInfo()
  const associate = storeAuth.storeGetSelectedAssociate();
  return (info && info.type == 'shop_account') || (associate && associate.signin_shop_id)
}

// 会員名取得
export function getUserName() {
  // 店舗共有モード（店舗アカウント以外）
  if (isShopShareMode() && !isUserShopAccount()) {
    return getUserShopName() + "(共有モード)"
  } else if (storeAuth.storeIsShopIdAccount()) {
    // 店舗IDの場合
    // TODO:店舗IDを指定できるように
    let shop_profile = storeAuth.storeGetShopProfile(storeAuth.storeGetSelectedAnotherShopId())
    if (shop_profile) {
      return shop_profile.user_name
    }
  }
  return getJoinUserName(storeAuth.storeGetAccount())
}
// 従業員名取得
export async function getEmployeeName() {
  let employee = null
  if (!isShopShareMode() || isUserShopAccount()) {
    employee = storeAuth.storeGetEmployee()
    if (!employee) {
      const employees = await paginationLoad(
        common_api.apiEmployeeSearchMyShop,
        { loaded: 1, page: 1, limit: 0 },
        {
          shop_id: storeAuth.storeGetKeyAsSelectedShop(),
          employee_status: 0,
          order: { col_num: 10, descasc: "asc" },
        }
      );
      employee = employees.data.find(
        (e) => e.employee.associate_id == storeAuth.storeGetKeyAsSelectedAssociate()
      );
      storeAuth.storeSetEmployee(employee)
    }
  }
  return employee ? getJoinUserName(employee.employee) : getUserName()
}
export function getJoinUserName(item) {
  return item ? item.first_name + " " + item.last_name : ""
}
export function getJoinUserNameKana(item) {
  return item ? item.first_name_kana + " " + item.last_name_kana : ""
}

// 会員アバター
export function getUserAvatar() {
  return storeAuth.storeGetAccount("avatar");
}

// フランチャイズ
export function isFranchiseOwnerHead() {
  const fc_owner = storeAuth.storeGetKeyAsSelectedFranchiseOwner()
  return fc_owner != undefined && fc_owner != null
}
export function getUserFcOwnerBrandName() {
  return storeAuth.storeGetKeyAsSelectedFranchiseOwner().brand_name + "（FC）"
}

// Cognito情報取得
export function getCognitoDomain() {
  let url = congito_config.cognitoBaseURL
  if (!isEnvProd()) {
    // MEMO：Vue開発環境用に特別処理
    return url.replace('.vue', '');
  }
  return url
}
export function getCognitoRegion() {
  return congito_config.region;
}
export function getCognitoPoolId() {
  return congito_config.pool_id;
}
export function getCognitoExternalClientId() {
  return congito_config.provider.external.client_id;
}
export function getCognitoExternalSecret() {
  return congito_config.provider.external.client_secret;
}
export function getCognitoExternalRedirectUri() {
  if (window.location.host.includes("localhost")) {
    return "http://" + window.location.host
  }
  return "https://" + window.location.host
}
export function getCognitoAppClientId() {
  return congito_config.provider.app.client_id;
}
export function getCognitoAppSecret() {
  return congito_config.provider.app.client_secret;
}

// State文字列生成
export function generateState() {
  return generateRandamString(16, "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789");
}

// 外部認証一覧
export function getExternalSigninList() {
  return externalSignin
}

// アプリケーション表示
export function openApplication(params) { // eslint-disable-line
  let url = config.application + "://"
  // TODO:パラメータ渡し制御が必要

  window.location = url
}

// 有料会員判定
export function isPaidMembership() {
  return storeAuth.storeGetSelectedAssociate() && storeAuth.storeGetSelectedAssociate().membership == MemberShips.PAID
}
// タイマネ利用判定
export function isEnabledTimemng() {
  if (isEnvProd()) {
    return isPaidMembership()
  } else {
    // 開発環境はひとまず制限なし
    return true
    // return isPaidMembership()
  }
}

// 外部認証判定
export function isSigninTypeExternal() {
  let info = storeAuth.storeGetSigninInfo()
  if (info.type == 'email' && info.id) return false
  if (info.type == 'id' && info.id) return false
  return true
}

// 代理ログイン中判定
export function isProxySigninAccount() {
  return storeAuth.storeGetAccount("proxy_signin") ? true : false
}

// ポータル利用規約
export async function getTermsPortal(id_token = null) {
  const terms = await auth_api.apiAccountGetTerms(id_token, [TermTypes.PORTAL_TERM, TermTypes.PORTAL_PRIVACY]);
  return {
    "term": terms && terms.find(v => v.term_type == TermTypes.PORTAL_TERM && v.agree == TermAgree),
    "privacy": terms && terms.find(v => v.term_type == TermTypes.PORTAL_PRIVACY && v.agree == TermAgree)
  }
}
export async function agreeTermsPortal(id_token = null) {
  try {
    await auth_api.apiAccountAgreeTerms(id_token, TermTypes.PORTAL_TERM, TermAgree)
    await auth_api.apiAccountAgreeTerms(id_token, TermTypes.PORTAL_PRIVACY, TermAgree)
  } catch (err) {
    // MEMO:同意保存が出来なくても、ログインは出来るようにしておく
    errorLog(getRouteName(), "agreeTermsPortal", err);
  }
}

// 外部認証
export function isExternalSigninTypeGoogle(signin_info) {
  return signin_info.type == 'google'
}
export function isExternalSigninTypeLine(signin_info) {
  return signin_info.type == 'line'
}
export function isExternalSigninTypeApple(signin_info) {
  return signin_info.type == 'signinwithapple'
}

export default {
  getSigninUrl,
  getSignoutUrl,
  redirectSignin,
  redirectSignout,
  afterSinginMove,
  checkProfileError,
  checkAccountStatus,
  authSigninEmail,
  initOAuthEmail,
  authSigninExternalConfirm,
  initOAuthExternal,
  reloadProfile,
  isShopShareMode,
  isEnabledShopShareMode,
  readCompanyProfile,
  getUserProfileData,
  setDisplayShop,
  resetSelectedShopInfo,
  readStaffGroupSso,
  getSelectedStaffGroupSso,
  getAccountAvatarUrl,
  getUserInfo,
  getUserCompanyName,
  getUserCompanyFullName,
  getUserShopName,
  getUserBrandName,
  getUserRoleName,
  isUserHeadShop,
  isUserShopAccount,
  getUserName,
  getEmployeeName,
  getJoinUserName,
  getJoinUserNameKana,
  getUserAvatar,
  isFranchiseOwnerHead,
  getUserFcOwnerBrandName,
  getCognitoDomain,
  getCognitoRegion,
  getCognitoPoolId,
  getCognitoExternalClientId,
  getCognitoExternalSecret,
  getCognitoExternalRedirectUri,
  getCognitoAppClientId,
  getCognitoAppSecret,
  generateState,
  getExternalSigninList,
  openApplication,
  isPaidMembership,
  isEnabledTimemng,
  isSigninTypeExternal,
  isProxySigninAccount,
  getTermsPortal,
  agreeTermsPortal,
  isExternalSigninTypeGoogle,
  isExternalSigninTypeLine,
  isExternalSigninTypeApple,
}

const externalSignin = [
  {
    key: 'Google',
    text: 'Googleでサインイン',
    icon: '/external/Google-Button.svg',
    css: "",
  },
  {
    key: 'LINE',
    text: 'LINEでログイン',
    icon: '/external/line_logo_white.png',
    css: "",
  },
  // {
  //   key: 'Salesforce',
  //   text: 'SalesForce',
  //   icon: '/external/Salesforce.com_logo.svg',
  //   css: "",
  // },
]

const MemberShips = {
  PAID: '7',  // 有料会員
  FREE: '9',  // 無料会員
}

// 規約
const TermTypes = {
  PORTAL_TERM: 0,     // （ポータル）利用規約
  PORTAL_PRIVACY: 1,  // （ポータル）プライバシーポリシー
}
const TermAgree = 0 // 規約同意