import api_common from "@/helpers/api_common.js";

export default {

    // ルーチン一覧
    getTaskList(apiconf, staff_id, spec_date, past_present_future, shop_id, task_type) {
        const query = { staff_id: staff_id, spec_date: spec_date, past_present_future: past_present_future }
        if (shop_id) query.shop_id = shop_id
        if (task_type) query.task_type = task_type
        return api_common.callPostApi({
            conf: apiconf,
            path: "task/today",
            query: query,
        })
    },
    getEmergencyList(apiconf, staff_id, spec_date, shop_id) {
        const query = { staff_id: staff_id, spec_date: spec_date }
        if (shop_id) query.shop_id = shop_id
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/today",
            query: query,
        })
    },
    getChecksheetList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 日付基準取得
    getEmergencyToday(apiconf, staff_id, conditions) {
        const query = { staff_id: staff_id, conditions: conditions }
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/task/today",
            query: query,
        })
    },

    // 実績入力
    doneTask(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "routine/task/done",
            formdata: formdata,
        })
    },
    // サブタスク実績入力
    doneTaskSub(apiconf, staff_id, task, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    task: task,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "routine/tasksub/done",
            formdata: formdata,
        })
    },
    // 臨時タスク実績入力
    doneEmergency(apiconf, staff_id, emergency, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    emergency: emergency,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "emergency/done",
            formdata: formdata,
        })
    },

    // タスク店舗仕込み量設定
    updateTaskShopDish(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/event/update/prepare",
            query: { staff_id: staff_id, task: task },
        })
    },

    // 添付ファイル取得
    taskLogfileList(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/log/files",
            query: { staff_id: staff_id, task: task },
        })
    },

    // タスクコメント
    taskCommentList(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/task/comment/list",
            query: { staff_id: staff_id, task: task },
        })
    },
    upsertTaskComment(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/task/comment/upsert",
            query: { staff_id: staff_id, task: task },
        })
    },
    emergencyCommentList(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/task/comment/list",
            query: { staff_id: staff_id, task: task },
        })
    },
    upsertEmergencyComment(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/task/comment/upsert",
            query: { staff_id: staff_id, task: task },
        })
    },

    // キャンセル
    cancelTask(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/task/cancel",
            query: { staff_id: staff_id, task: task },
        })
    },
    cancelTaskSub(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/tasksub/cancel",
            query: { staff_id: staff_id, task: task },
        })
    },

    // 履歴
    logsListTask(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/task/logs/list",
            query: { staff_id: staff_id, task: task },
        })
    },
    logsListTaskSub(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/tasksub/logs/list",
            query: { staff_id: staff_id, task: task },
        })
    },
    logsListEmergency(apiconf, staff_id, emergency) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/logs/list",
            query: { staff_id: staff_id, emergency: emergency },
        })
    },

    // チェックシート
    doneChecksheet(apiconf, staff_id, checksheet) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/done",
            query: { staff_id: staff_id, checksheet: checksheet },
        })
    },
    checksheetColumnAddAttachment(apiconf, staff_id, checksheet, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    checksheet: checksheet,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "checksheet/event/log/add/attachment",
            formdata: formdata,
        })
    },
    checksheetColumnRemoveAttachment(apiconf, staff_id, checksheet) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/event/log/rm/attachment",
            query: { staff_id: staff_id, checksheet: checksheet },
        })
    },

    getChecksheetResults(apiconf, staff_id, checksheet) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/results",
            query: { staff_id: staff_id, checksheet: checksheet },
        })
    },
    getChecksheetPeriodResults(apiconf, staff_id, checksheet, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/period/results",
            query: { staff_id: staff_id, checksheet: checksheet, page: page },
        })
    },
    exportChecksheetPeriodResults(apiconf, staff_id, checksheet, export_format) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "checksheet/period/results/export",
            query: {
                staff_id: staff_id,
                checksheet: checksheet,
                export_format: export_format,
            },
        })
    },

    // 月別計画リスト
    getPlansPattern(apiconf, staff_id, spec_year, spec_month, shop_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "plans/pattern",
            query: { staff_id: staff_id, spec_year: spec_year, spec_month: spec_month, shop_id: shop_id },
        })
    },
    getPlansEmergency(apiconf, staff_id, spec_year, spec_month, shop_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "plans/emergency",
            query: { staff_id: staff_id, spec_year: spec_year, spec_month: spec_month, shop_id: shop_id },
        })
    },

    // 日報一覧
    getDailyReports(apiconf, staff_id, shop_id, spec_date, request_day_count, withlogs = 0) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/daily_reports",
            query: { staff_id: staff_id, shop_id: shop_id, spec_date: spec_date, request_day_count: request_day_count, withlogs: withlogs },
        })
    },
    getRoutineDailyReportList(apiconf, staff_id, report) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/dailyreport/list",
            query: { staff_id: staff_id, report: report },
        })
    },

    // プレビュー
    previewTaskFile(apiconf, staff_id, routine_task_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/routine/task/file",
            query: {
                staff_id: staff_id,
                routine_task_id: routine_task_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    previewTaskSubFile(apiconf, staff_id, routine_task_sub_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/routine/tasksub/file",
            query: {
                staff_id: staff_id,
                routine_task_sub_id: routine_task_sub_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    previewTaskLogFile(apiconf, staff_id, routine_task_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/routine/task/result/file",
            query: {
                staff_id: staff_id,
                routine_task_id: routine_task_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    previewTaskSubLogFile(apiconf, staff_id, routine_task_sub_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/routine/tasksub/result/file",
            query: {
                staff_id: staff_id,
                routine_task_sub_id: routine_task_sub_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    previewEmergencyLogFile(apiconf, staff_id, emergency_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/emergency/result/file",
            query: {
                staff_id: staff_id,
                emergency_id: emergency_id,
                file_id: file_id,
                original: original,
            },
        })
    },
    previewChecksheetColumn(apiconf, staff_id, checked_column_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/checksheet/log/file",
            query: {
                staff_id: staff_id,
                checked_column_id: checked_column_id,
                file_id: file_id,
                original: original,
            },
        })
    },

    // （本部用）ダッシュボード
    getHeadDashborad(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "head/dashboard",
            query: {
                staff_id: staff_id,
                conditions: conditions,
            },
        })
    },

    // （エリアマネージャー用）ダッシュボード
    getAreaDashborad(apiconf, staff_id, spec_date, past_present_future, include_head, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dashboard",
            query: {
                staff_id: staff_id,
                spec_date: spec_date,
                past_present_future: past_present_future,
                include_head: include_head,
                page: page,
            },
        })
    },
    getAreaDashboradDailyReports(apiconf, staff_id, spec_date, shop_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dashboard/daily/reports",
            query: {
                staff_id: staff_id,
                dashboard: {
                    spec_date: spec_date,
                    shop_id: shop_id,
                    page: page
                }
            },
        })
    },
    dashboradReportApprove(apiconf, staff_id, dashboard) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dashboard/report/approve",
            query: { staff_id: staff_id, dashboard: dashboard },
        })
    },
    dashboradEmergencyApprove(apiconf, staff_id, dashboard) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dashboard/emergency/approve",
            query: { staff_id: staff_id, dashboard: dashboard },
        })
    },
    dashboardChecksheeList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "dashboard/checksheet/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    dashboardHeadChecksheeList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "head/dashboard/checksheet/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // タスク実績一覧
    routineTaskStatusList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/task/status/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    routineTaskSubList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/tasksub/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    routineEmergencyStatusList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "emergency/task/status/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 実績一覧用タスク一覧
    routineTasksList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshops/task/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    routineEmergencyTasksList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshops/emergency/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 実施スキップ
    skipTask(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/task/skip",
            query: { staff_id: staff_id, task: task },
        })
    },
    skipTaskSub(apiconf, staff_id, task) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/tasksub/skip",
            query: { staff_id: staff_id, task: task },
        })
    },
    skipTaskSubDish(apiconf, staff_id, dish) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "routine/dish/skip",
            query: { staff_id: staff_id, dish: dish },
        })
    },
}