import api_common from "@/helpers/api_common.js";

export default {
    // サマリ
    getSummaryInfo(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "adv/dashboard/summary",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 店舗別
    getShopsInfo(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "adv/dashboard/shops",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 店舗詳細
    getShopDetail(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "adv/dashboard/shop/detail",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 臨時タスク
    getEmergenciesInfo(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "adv/dashboard/emergencies",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 臨時タスク詳細
    getEmergencyDetail(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "adv/dashboard/emergency/detail",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}
