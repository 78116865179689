import config from '@/configs/timemanagement';
export default
    [
        {
            path: config.basepath + '/todaytask',
            name: 'timemanagement-todaytask',
            component: () => import('@/components/timemanagement/routine/todaytask/IndexTodayTask'),
            meta: { title: '本日のタスク', policy: { is_head: false } },
        },
        {
            path: config.basepath + '/routine/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: 'checksheet/:cd',
                    name: 'timemanagement-routine-checksheet',
                    component: () => import('@/components/timemanagement/routine/checksheet/IndexRoutineChecksheet'),
                    meta: { title: 'チェックシート', policy: { is_head: false } },
                },
                {
                    path: 'checksheet',
                    name: 'timemanagement-routine-checksheet-list',
                    component: () => import('@/components/timemanagement/routine/checksheet/IndexRoutineChecksheetList'),
                    meta: { title: 'チェックシート', policy: { is_head: false } },
                },
                {
                    path: 'dailyreport',
                    name: 'timemanagement-routine-dailyreport',
                    component: () => import('@/components/timemanagement/routine/dailyreport/IndexRoutineDailyReport'),
                    meta: { title: '日報・引継ぎ', policy: { is_head: false, is_vmanage_use_only: true } },
                },
                {
                    path: 'dish',
                    name: 'timemanagement-routine-dish',
                    component: () => import('@/components/timemanagement/routine/dish/IndexRoutineDish'),
                    meta: { title: '仕込み', policy: { is_head: false } },
                },
            ]
        },
        {
            path: config.basepath + '/personal_task',
            name: 'timemanagement-personal-task',
            component: () => import('@/components/timemanagement/routine/personal_task/IndexRoutinePersonalTask'),
            meta: { title: '個人タスク' },
        },
    ]




