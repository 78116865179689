import { uuid } from "vue-uuid";
import crypto from "crypto-js";
import common from "@/configs/common"
import isMobile from "ismobilejs";
import imageCompression from 'browser-image-compression';
import storeAuth from "@/helpers/store/storeAuthHelper"
import storeSystem from "@/helpers/store/storeSystemHelper"
import { isEnvProd, isEnvStaging, isEnvRev } from "@/helpers/environment"
import { errorLog, warnLog } from "@/helpers/common/datadog"
import { apiShareFilePreview, apiFileGetPresignedUrl, apiChatGetUnreadMessage } from "@/helpers/common/common_api"
import { appBrowserOpen, appAndroidFileDownload } from "@/helpers/common/app"
import { getServiceBaseTitle } from "@/helpers/service_util"
import { isShopShareMode, isUserShopAccount } from "@/helpers/auth/auth_util"

// サイトタイトル設定
export function setServiceBaseTitle(title) {
  if (!title) {
    title = getTitle();
  }
  if (title == undefined) {
    document.title = getServiceBaseTitle();
  } else {
    document.title = `${getServiceBaseTitle()} | ${title}`;
  }
}

// ホストURL取得
export function getHostURL() {
  if (window.location.host.includes("localhost")) {
    return "http://" + window.location.host
  }
  return "https://" + window.location.host
}

// タイトル取得
export function getTitle() {
  return getRouteMeta('title', '');
}

// 対象ページポリシー取得
export function getRoutePolicy() {
  return getRouteMeta('policy', '');
}

// UNIX時刻⇒Date型変換
export function unixtimeToDate(unixtime) {
  return new Date(unixtime * 1000);
}

// UNIX時刻⇒文字列変換
export function unixtimeToStr(unixtime) {
  let dateTime = unixtimeToDate(unixtime);
  return dateTime.toLocaleDateString('ja-JP') + " " + dateTime.toLocaleTimeString('ja-JP');
}

// Date型⇒UNIX時刻変換
export function dateToUnixtime(date) {
  return Math.floor(date.getTime() / 1000);
}

// 有効期限チェック
export function isExpiredUnixtime(unixtime) {
  return (unixtime * 1000) < new Date().getTime();
}

// datetime-localフォーマット時刻取得
export function getNewDate(time) {
  return new Date(time.replaceAll("-", "/").replaceAll("T", " "))
}
export function formatDateStrByDBTime(dbtime, format, zero_padding) {
  if (!dbtime) return null
  try {
    return formatDateStr(getNewDate(dbtime), format, zero_padding)
  } catch {
    return dbtime
  }
}
export function formatDateStrByUnixtime(unixtime, format, zero_padding) {
  if (!unixtime) return null
  try {
    return formatDateStr(unixtimeToDate(unixtime), format, zero_padding)
  } catch {
    return unixtime
  }
}
export function formatDateStr(date, format = "YYYY/MM/DD hh:mm:ss", zero_padding = true) {
  if (!date) return null
  try {
    const YYYY = date.getFullYear();
    const MM = zeroPadding(date.getMonth() + 1)
    const DD = zeroPadding(date.getDate())
    const hh = zeroPadding(date.getHours())
    const mm = zeroPadding(date.getMinutes())
    const ss = zeroPadding(date.getUTCSeconds())
    const week = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];
    return format
      .replace("YYYY", YYYY)
      .replace("MM", MM)
      .replace("DD", DD)
      .replace("hh", hh)
      .replace("mm", mm)
      .replace("ss", ss)
      .replace("W", week)
  } catch {
    return date
  }
  function zeroPadding(value) {
    return zero_padding ? ("0" + value).slice(-2) : value;
  }
}

// API送信用年月日変換
export function formatDateForSendApiYmd(date) {
  let _date = null
  if (date instanceof Date) {
    _date = date
  } else {
    try {
      _date = getNewDate(date)
    } catch { }// eslint-disable-line
  }
  return formatDateStr(_date, "YYYY-MM-DD", true)
}

// 日付比較
export function diffDateYmd(date1, date2) {
  const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
  const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);
  if (d1.getTime() > d2.getTime()) {
    return -1
  } else if (d1.getTime() < d2.getTime()) {
    return 1
  }
  return 0
}
// 月比較
export function diffDateYm(date1, date2) {
  const d1 = new Date(date1.getFullYear(), date1.getMonth(), 1, 0, 0, 0);
  const d2 = new Date(date2.getFullYear(), date2.getMonth(), 1, 0, 0, 0);
  if (d1.getTime() > d2.getTime()) {
    return -1
  } else if (d1.getTime() < d2.getTime()) {
    return 1
  }
  return 0
}
// 月末取得
export function getDateByMonthLast(target_date) {
  let date = new Date(target_date)
  date.setDate(1)
  date.setMonth(date.getMonth() + 1)
  date.setDate(date.getDate() - 1)
  return date
}
// 週先頭（月曜）取得
export function getDateByWeekFirst(target_date) {
  let date = new Date(target_date)
  for (let i = 0; i < 6; i++) {
    if (date.getDay() == 1) break
    date.setDate(date.getDate() - 1)
  }
  return date
}
// 週最後（日曜）取得
export function getDateByWeekLast(target_date) {
  let date = new Date(target_date)
  if (date.getDay() == 0) return date
  for (let i = 0; i < 6; i++) {
    if (date.getDay() == 0) break
    date.setDate(date.getDate() + 1)
  }
  return date
}

// 入力フォーム値成形取得
export function getFormValues(params) {
  let results = {}
  Object.keys(params).forEach(key => {
    if (params[key] != null) {
      if (typeof (params[key]) == "object") {
        results[key] = params[key].value
      } else {
        results[key] = params[key]
      }
    }
  });
  return results;
}
// JSON値渡し
export function copyJson(values) {
  return JSON.parse(JSON.stringify(values));
}

// 文字列項目の判定
export function isStrNull(item) {
  return !item || !item.trim()
}
export function strIncludes(item, search) {
  return item && item.includes(search)
}

// 数値最小値チェック: allow_decimal_pointは小数点を許容するか
export function inputLimitCheck(limit, upper, lower, is_allow_decimal_point = false) {
  let value;
  const split_lower = lower.split(".");
  if (split_lower.length > 1) {
    value = new String(
      numberFloor(limit, split_lower[1].length * 10)
    ).toString();
  } else {
    value = is_allow_decimal_point
      ? new String(numberPow(limit, 1)).toString() // 小数第二位以下を切り捨てる
      : value = new String(limit).toString().split(".")[0]

  }
  if (Number(value) > Number(upper)) {
    return upper;
  } else if (Number(value) < Number(lower)) {
    return lower;
  } else {
    return value;
  }
}

// datetime-localフォーマット時刻取得
export function getDatetimeLocalFormat(dateTime) {
  const yyyy = dateTime.getFullYear();
  const MM = ("0" + (dateTime.getMonth() + 1)).slice(-2);
  const dd = ("0" + dateTime.getDate()).slice(-2);
  const hh = ("0" + dateTime.getHours()).slice(-2);
  const mm = ("0" + dateTime.getMinutes()).slice(-2);
  return yyyy + "-" + MM + "-" + dd + "T" + hh + ":" + mm;
}

// UTCフォーマット時刻取得
export function getDatetimeUTCFormat(dateTime) {
  const yyyy = dateTime.getUTCFullYear();
  const MM = ("0" + (dateTime.getUTCMonth() + 1)).slice(-2);
  const dd = ("0" + dateTime.getUTCDate()).slice(-2);
  const hh = ("0" + dateTime.getUTCHours()).slice(-2);
  const mm = ("0" + dateTime.getUTCMinutes()).slice(-2);
  const ss = ("0" + dateTime.getUTCSeconds()).slice(-2);
  return yyyy + "-" + MM + "-" + dd + " " + hh + ":" + mm + ":" + ss;
}

// ランダム文字列生成
export function generateRandamString(len, str) {
  if (str != undefined) {
    str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  }
  var result = "";
  for (var i = 0; i < len; i++) {
    result += str.charAt(Math.floor(Math.random() * str.length));
  }
  return result;
}

// DynamoDB Datetime ⇒ 文字列
export function dynamoDatetimeToStr(datetime) {
  if (!datetime) {
    return datetime
  }
  if (!isNaN(datetime)) {
    return unixtimeToStr(datetime)
  }
  let _date = datetime;
  if (_date.includes("+")) {
    _date = _date.substring(0, _date.indexOf("+"))
  }
  if (_date.includes(".")) {
    _date = _date.substring(0, _date.indexOf("."))
  }
  return _date;
}

// 配列カラム取得
export function getArrayColumn(arr, column, value, get_column) {
  const result = arr.find(v => v[column] == value)
  if (result && get_column) {
    return result[get_column]
  }
  return result
}

// 配列カラム結合
export function getArrayJoinColumn(arr, column, separator = ",") {
  let columns = []
  arr.forEach(v => {
    const c = v[column];
    if (c) {
      columns.push(c)
    }
  })
  return columns.join(separator)
}

// データソート
export function dataSort(elem, keys, isasc = true) {
  let _keys = keys
  if (!Array.isArray(_keys)) {
    _keys = [keys]
  }
  elem.sort(function (a, b) {
    let va = _get(a, _keys, 0);
    let vb = _get(b, _keys, 0);
    if (isasc) {
      if (va > vb) return 1;
      if (va < vb) return -1;
    } else {
      if (va > vb) return -1;
      if (va < vb) return 1;
    }
    return 0;
  });
  function _get(value, ks, idx) {
    const key = ks[idx]
    if (!key) {
      return value
    }
    const _value = value[key]
    if (_value) {
      if (ks.length > idx) {
        return _get(_value, ks, idx + 1)
      }
    }
    return _value
  }
}

// 配列連結
export function arrayConcat(arr1, arr2, exclude_null = true) {
  let arr = arr1.concat(arr2)
  if (exclude_null) {
    arr = arr.filter(v => v)
  }
  return arr
}

// UUID(V4)生成
export function generateUUID_V4() {
  return uuid.v4();
}

// 自動リンク書き換え
export function autoLink(text) {
  return text
    ? text.replace(/(https?:\/\/[^\s]*)/g, "<a target='_blank' href='$1'>$1</a>")
    : "";
}
// 文字列短縮表示
export function ellipsisText(text, minlen = 100) {
  if (text.length > minlen) {
    return text.substring(0, minlen) + "...";
  }
  return text;
}

// AES暗号化・複合化
export function cryptoAES(str) {
  return crypto.AES.encrypt(str, common.crypto_key).toString();
}
export function decryptoAES(encrypted_str) {
  return crypto.AES.decrypt(encrypted_str, common.crypto_key).toString(crypto.enc.Utf8);
}

// 現在ページパス
export function getCurrentRoutePath() {
  return getRouter().currentRoute.path
}

// ページリロード
export function reloadPage() {
  getRouter().go({ path: getCurrentRoutePath(), force: true })
}

// 外部画面表示
export function openExternalWindow(url, target = "_blank") {
  if (isSystemApp()) {
    appBrowserOpen(url);
  } else {
    window.open(url, target)
  }
}

// 添付ファイルアイコン取得
export function getAttachedFileIcon(ext) {
  if (ext == "pdf") {
    return "mdi-pdf-box";
  }
  return "mdi-file-document";
}

// ファイル名取得
export function getFileName(filepath) {
  return filepath ? filepath.slice(filepath.lastIndexOf('/') + 1) : filepath;
}

// 拡張子取得
export function getExp(filename) {
  return filename ? filename.slice(filename.lastIndexOf('.') + 1).toLowerCase() : filename;
}

// ファイル種別判定
export function isImageType(type) {
  return ImageTypes.find(v => {
    if (v.exp == type) return true
    if (Array.isArray(v.mimetype)) {
      return v.mimetype.find(m => m == type) != undefined
    } else {
      return v.mimetype == type
    }
  }) != undefined
}
export function isFileExp(type) {
  return FileTypes.find(v => {
    if (v.exp == type) return true
    if (Array.isArray(v.mimetype)) {
      return v.mimetype.find(m => m == type) != undefined
    } else {
      return v.mimetype == type
    }
  }) != undefined
}
export function isFileVideo(type) {
  return ImageMovies.find(v => {
    if (v.exp == type) return true
    if (Array.isArray(v.mimetype)) {
      return v.mimetype.find(m => m == type) != undefined
    } else {
      return v.mimetype == type
    }
  }) != undefined
}

// MIMEタイプ取得
export function getMimeType(exp) {
  let type = ImageTypes.find(v => v.exp == exp)
  if (type) {
    return type.mimetype
  }
  type = FileTypes.find(v => v.exp == exp)
  if (type) {
    return type.mimetype
  }
  return null;
}
export function getEnabledAcceptType() {
  let types = []
  for (const type of ImageTypes) {
    types.push(type.mimetype)
  }
  for (const type of FileTypes) {
    types.push(type.mimetype)
  }
  return types.join(",");
}
export function getEnabledAcceptTypeImage() {
  let types = []
  for (const type of ImageTypes) {
    types.push(type.mimetype)
  }
  return types.join(",");
}

// 画像のサイズを取得
export function getImgSize(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  });
}

// 添付ファイルプレビュー（共有ファイル含む）
export async function previewAttachedWithShareFile(file, callfunc, callkey, imageonly = false, image_with_pdf = false, image_with_video = false, is_thumbnail = true) {
  if (file.read_attached && !file.read_attached.reload) return;
  let errors = []
  const isshared = file.ext != undefined && file.ext != null
  const isimage = isImageType(getExp(file.extension)) || isImageType(getExp(file.ext))
  const ispdf = file.extension == ".pdf" || file.ext == ".pdf"
  const isvideo = isFileVideo(getExp(file.extension)) || isFileVideo(getExp(file.ext))
  if (isvideo) file.video_uuid = generateUUID_V4()
  const original = is_thumbnail ? 1 : 0
  file.read_attached = {
    isimage: isimage,
    ispdf: ispdf,
    isvideo: isvideo,
  };
  if (imageonly && !isimage) {
    if (ispdf) {
      if (!image_with_pdf) return
    } else if (isvideo) {
      if (!image_with_video) return
    } else {
      return
    }
  }
  // if (imageonly && image_with_pdf && !ispdf) {
  //   file.read_attached = {
  //     isimage: false,
  //     ispdf: ispdf,
  //     isvideo: isvideo,
  //   };
  // }
  if (!isshared && callfunc) {
    try {
      const result = await callfunc(callkey, file.file_id, original);
      file.read_attached.data = result.data
      return;
    } catch (err) {
      errors.push(err)
    }
  }
  try {
    const result = await apiShareFilePreview(file.file_id, original);
    file.read_attached.data = result.data
  } catch (err) {
    errors.push(err)
    file.read_attached.noimage = true
    file.read_attached.errors = errors
  }
}

// 添付ファイルダウンロード
export async function attachedFileDownload(blob, filename) {
  // if (isSystemAndroidApp()) {
  if (isSystemApp()) {
    try {
      // Androidアプリ専用対応
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        const indexOfFirst = base64data.indexOf(",");
        await appAndroidFileDownload(filename, base64data.slice(indexOfFirst + 1))
      }
    } catch { } // eslint-disable-line
  } else {
    try {
      const a = document.createElement("a");
      const myURL = window.URL || window.webkitURL;
      a.href = myURL.createObjectURL(blob);
      a.download = filename;
      a.click();
    } catch { } // eslint-disable-line
  }
  await waitSleepMsec(100)  // ダウンロード表示する為に一応最後に少しスリーブ
}
export function getAttachedImageSrc(data) {
  if (data) {
    try {
      const url = window.URL || window.webkitURL;
      return url.createObjectURL(data);
    } catch { } // eslint-disable-line
  }
}
// 添付ファイル名
export function getAttachedFileName(file) {
  if (file.upload) return file.upload.name
  if (file.original_name) return file.original_name
  return file.name
}

// PDF表示
export function getAttachedPdfSrc(data) {
  if (data) {
    try {
      const url = window.URL || window.webkitURL;
      return "/js/pdfjs-dist/web/viewer.html?file=" + url.createObjectURL(data);
    } catch { } // eslint-disable-line
  }
}

// ユーザエージェント判定
export function getUserAgent() {
  try {
    // MEMO：将来的にChrome等ではuserAgentが使えなくなり、userAgentDataを利用する必要がある模様
    return window.navigator.userAgent || window.navigator.userAgentData;
  } catch (err) {
    return ""
  }
}
export function isUserAgentPhone() {
  return isMobile(getUserAgent()).phone
}
export function isUserAgentTablet() {
  // MEMO:iPadは含まれなくなっているので注意
  return isMobile(getUserAgent()).tablet
}
export function isUserAgentAny() {
  return isMobile(getUserAgent()).any
}

// アプリ用システム制御判定
export function isSystemApp() {
  return storeAuth.storeGetSystemMode() == common.system_mode.smartphone_app;
}
export function isSystemWeb() {
  return !isSystemApp();
}
export function getSystemType() {
  return isSystemApp() ? common.system_mode.smartphone_app : common.system_mode.website;
}
export function isSystemAndroidApp() {
  return isSystemApp() && getOSType() == 'Android'
}
export function isSystemiOSApp() {
  // MEMO:UAでiPad判定が困難になっているため、AndroidでなければiOSアプリ扱い
  return isSystemApp() && getOSType() != 'Android'
}


// 表示モード
export function isDisplayModeSP() {
  // if (isSystemApp()) return true
  if (!isEnvProd() && storeSystem.storeGetDisplayMode()) return storeSystem.storeGetDisplayMode() == "sp"
  return isUserAgentPhone()
}
export function isDisplayModeTAB() {
  // if (isSystemApp()) return false
  if (!isEnvProd() && storeSystem.storeGetDisplayMode()) return storeSystem.storeGetDisplayMode() == "tab"
  return isUserAgentTablet()
}
export function isDisplayModePC() {
  return !isDisplayModeSP() && !isDisplayModeTAB()
}
export function getMobileBreakpoint() {
  return isDisplayModeSP() ? 9999 : 0;
}

// ベースURL
export function browserSiteUrl() {
  return window.location.protocol + "//" + window.location.host + "/"
}
export function iOSAppUrl() {
  return "eatery_portal" + "://"
}
export function androidAppUrl() {
  return "eatery_portal" + "://"
}

// ページ遷移
export function pageMove(page) {
  let to = ""
  if (page.external) {
    openExternalWindow(page.external);
    return
  }
  if (page.linked_path && page.external_browser_only && !isSystemApp()) {
    openExternalWindow(page.linked_path, page.target);
    return
  }

  if (page.to) {
    to = page.to
  } else if (page.linked_path) {
    to = page.linked_path
  } else {
    to = page
  }
  if (getRoutePath() == to) {
    return
  }
  routerPush(to)
}

// ルーティング情報
export function getRoute() {
  return storeSystem.storeGetRoute()
}
export function getRouteMeta(key, def = null) {
  const route = getRoute()
  if (!route || !route.meta) return def
  return key ? route.meta[key] : route.meta
}
export function getRouteName() {
  return storeSystem.storeGetRoute().name
}
export function getRoutePath() {
  return storeSystem.storeGetRoute().path
}
export function getRouter() {
  return storeSystem.storeGetRouter()
}
export function routerPush(to) {
  return getRouter().push(to)
}

// 招待QR用データ生成
export function generateInvitationQRData(invitation) {
  const query = "?ivc=" + invitation.invitation_id;
  return {
    browser: browserSiteUrl() + query,
    ios: iOSAppUrl() + query,
    android: androidAppUrl() + query,
  };
}

// 性別リスト
export function getCommonListGender(include_space = true) {
  if (include_space) {
    return listGender
  } else {
    return listGender.filter(v => v.text != "")
  }
}

// 事業タイプ（法人格）リスト
export function getCompanyFormList(corporate_only = false) {
  return corporate_only ? listCompanyForm.filter(v => v.value != '9') : listCompanyForm
}
export function getCompanyFormName(company) {
  const form = listCompanyForm.find(v => v.value == company.company_form)
  return form && form.value != "9" ? form.text : ""
}
export function getCompanyFormKana(company) {
  const form = listCompanyForm.find(v => v.value == company.company_form)
  return form ? form.kana : ""
}
export function getCompanyFullName(company, is_space = true) {
  const form_name = getCompanyFormName(company)
  if (company.form_name_position == 0) {
    return form_name + (is_space ? " " : "") + company.company_name
  } else {
    return company.company_name + (is_space ? " " : "") + form_name
  }
}
export function getCompanyFullKana(company, is_space = true) {
  const form_name = getCompanyFormKana(company)
  if (company.form_name_position == 0) {
    return form_name + (is_space ? " " : "") + company.company_name_kana
  } else {
    return company.company_name_kana + (is_space ? " " : "") + form_name
  }
}

// 事業タイプ
export function getCompanyCorporateTypes() {
  return listCorporateType
}
export function getCompanyCorporateTypeText(value) {
  return getArrayColumn(listCorporateType, "value", new String(value), "text")
}

// 従業員種別リスト
export function getEmployeePermanentList() {
  return listEmployeePermanent
}

// 本店判定
export function isShopHead(shop) {
  return shop && shop.is_head == 0
}

// 削除実行フラグ
export function getDeleteExecFlg() {
  return common.delete_flg.true
}
// APIステータスコード
export function getApiStatusCodeInvalid() {
  return common.api_status.invalid
}

// 小数四捨五入
export function numberRound(value, base) {
  return Math.round(value * base) / base;
}

// 小数切り上げ
export function numberCeil(value, base) {
  return Math.ceil(value * base) / base;
}

// 小数切り捨て
export function numberFloor(value, base) {
  return Math.floor(value * base) / base;
}

// 任意の小数点桁数切り捨て
export function numberPow(value, decimalPlaces) {
  const base = Math.pow(10, decimalPlaces)
  return Math.floor(value * base) / base;
}

// NotFound表示
export function redirectNotFound() {
  document.title = "ページが見つかりません"
  routerPush("/notfound");
}

// メンテナンスページ
export function redirectMaintenancePage() {
  window.location.href = "/maintenance/" + (isDisplayModeSP() ? "sp/" : "")
}

// アプリ用ファイル読み込み
export function readyAppUploadPhoto() {
  photoApp.postMessage(""); // eslint-disable-line
}
export function readyAppUploadImage() {
  imagePickerApp.postMessage(""); // eslint-disable-line
}
export function readyAppUploadFile() {
  fileApp.postMessage(""); // eslint-disable-line
}
export async function execFinishAppUpload(filename, mimetype, base64data, error, _callback) {
  if (!error) {
    try {
      var bin = atob(base64data.replace(/^.*,/, ""));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      return _callback(new File([buffer.buffer], filename, { type: mimetype }));
    } catch (err) {
      errorLog(getRouteName(), "execFinishAppUpload", err)
    }
    return null
  }
}

// 画像圧縮
export async function compressUploadImage(file, is_file = true) {
  if (file) {
    if (file.type && file.type.startsWith("image/") &&
      file.size >= (common.image_compression.maxSizeMB * 1024 * 1024)) {
      // MEMO：調査用ログ
      warnLog(getRouteName(), "compressUploadImage",
        "画像圧縮 " + numberFloor(file.size / 1024 / 1024, 10) + "MB " + file.name + " " + file.type)
      let blob = await imageCompression(file, common.image_compression)
      if (is_file) {
        return new File([blob], blob.name, { type: blob.type })
      }
      return blob
    }
    return file
  }
  return null
}
// 画像圧縮不可判定
export function restrictionCompressImage(file,
  error = "アップロードされたファイルはサポートされていません。\nPNG / JPG形式でアップロードしてください。") {
  // MEMO：調査用ログ
  warnLog(getRouteName(), "restrictionCompressImage",
    " file:" + file.name +
    " type:" + file.type +
    " check1:" + ['dng', 'heic'].includes(getExp(file.name)) +
    " check2:" + ['image/dng'].includes(file.type.toLowerCase()))
  if (['dng', 'heic'].includes(getExp(file.name))) return error
  if (['image/dng'].includes(file.type.toLowerCase())) return error
}

// 実行中制御
export function startExecute(self) {
  if (self.$refs.execute) {
    self.$refs.execute.view(true)
  }
}
export function finishExecute(self) {
  if (self.$refs.execute) {
    self.$refs.execute.view(false)
  }
}
export function startExecuteLinear(self) {
  if (self.$refs.execute) {
    self.$refs.execute.view(true, 'linear')
  }
}
export function setProgress(self, progress) {
  if (self.$refs.execute) {
    self.$refs.execute.setProgress(progress)
  }
}

// デザインモード判定
export function isNewDesignMode() {
  return storeAuth.storeGetDesignMode() == "new"
}

// OS判定
export function getOSType() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("windows nt") !== -1) {
    return "Windows"
  } else if (ua.indexOf("android") !== -1) {
    return "Android"
  } else if (ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1 ||
    ua.indexOf("macintosh") !== -1 && 'ontouchend' in document) {
    return "iOS"
  } else if (ua.indexOf("mac os x") !== -1) {
    return "macOS"
  }
}
export function isWindowsOS() {
  return getOSType() == "Windows"
}
export function isiOS() {
  return getOSType() == "iOS"
}

// 店舗営業時間
export function getShopTimesWeekdays() {
  return ShopTimesWeekdays
}
export function getShopTimesWeekdayText(value) {
  return getArrayColumn(ShopTimesWeekdays, "value", value, "text")
}
export function getDisplayShopTime(time) {
  return time ? time.substr(0, 2) + ":" + time.substr(2, 4) : null
}
export function formatShopTimes(shop_times) {
  let results = [];
  for (const weekday of getShopTimesWeekdays()) {
    let state = true
    let times = [];
    for (const shop_time of shop_times.filter(
      (v) => v.weekday == weekday.value
    )) {
      times.push({
        start: getDisplayShopTime(shop_time.start_time),
        end: getDisplayShopTime(shop_time.end_time),
      });
    }
    if (times.length == 0) {
      state = false,
        times = [{ start: null, end: null }];
    }
    results.push({
      id: weekday.value,
      date: { code: weekday.value, text: weekday.text },
      state: state,
      times: times,
      is_fulltime: times.length == 1 && times[0].start_time == times[0].end_time,
    });
  }
  return results
}

// フランチャイズ
export function getFranchiseInfo(shop) {
  let info = {
    is_myshop: shop.shop.operating_company_id == storeAuth.storeGetKeyAsSelectedCompany(),
    is_franchise: shop.shop.is_head == 1 &&
      shop.shop.brand_company_id &&
      (shop.shop.brand_company_id != shop.shop.shop_company_id || shop.shop.brand_company_id != shop.shop.operating_company_id),
    is_franchiser: false,
    is_franchisee: false,
  }
  if (info.is_franchise) {
    info.is_franchiser = shop.shop.brand_company_id && shop.shop.brand_company_id == storeAuth.storeGetKeyAsSelectedCompany()
    info.is_franchisee = shop.shop.brand_company_id && shop.shop.brand_company_id != storeAuth.storeGetKeyAsSelectedCompany()
  }
  return info
}
export function isFranchiseShop(shop) {
  return shop.franchise_info.is_franchise
}
export function isFranchiserShop(shop) {
  return shop.franchise_info.is_franchiser
}
export function isFranchiseeShop(shop) {
  return shop.franchise_info.is_franchisee
}
export function isFranchiseHQShop(shop) {
  return !shop.franchise_info.is_franchise && !shop.franchise_info.is_myshop
}
export function getBrandCompanyName(shop, _franchisee) {
  if (isFranchiserShop(shop)) {
    return "自社"
  } else {
    const franchisee = _franchisee.find(v => v.brand_id == shop.brand_ids[0])
    if (franchisee) {
      return franchisee.brand_company_name
    }
  }
}
export function getBrandOperationCompanyName(shop, _franchisee) {
  if (isFranchiserShop(shop, _franchisee)) {
    return shop.shop.operating_company_name
  } else if (isFranchiseHQShop(shop, _franchisee)) {
    return shop.shop.brand_company_name
  }
  return "自社"
}

// スリーブ
export async function waitSleep(sec = 1) {
  await waitSleepMsec(sec * 1000)
}
export async function waitSleepMsec(msec = 1000) {
  const sleep = (milisecond) =>
    new Promise((resolve) => setTimeout(resolve, milisecond));
  await sleep(msec);
}

// アップロードエラーメッセージ取得
export function getUploadErrorMessage(err, def = "") {
  if (err) {
    const msg = err.message ?? err
    switch (msg) {
      case "over capacity.":
        return "容量不足の為、ファイルを送信できませんでした。本部管理者にお問い合わせください。"
      case "Request failed with status code 413":
        return AttachedFilesLimitOverErrorMessage
    }
  }
  return def
}

// 店舗毎のVManage利用判定
export function existShopVManageItem(item) {
  return item && item.vmanage_shop
}
export function isShopUseVManage(item) {
  return isShopVManageWaitingToStart(item) || isShopVManageInUse(item)
}
export function isShopEditableVManage(item) {
  return isShopVManageWaitingToStart(item) || isShopVManageInUse(item) || isShopVManageInUseScheduledToEnd(item)
}
export function isShopVManageNoUse(item) {
  if (!existShopVManageItem(item)) return true
  return diffDateYm(getNewDate(item.vmanage_shop.start_date), getNewDate("2100/01/01")) == 0;
}
export function isShopVManageWaitingToStart(item) {
  if (isShopVManageNoUse(item)) return false;
  if (item.vmanage_shop.scheduled_to_leave == ShopVManageScheduledToLeave.TRUE) return false
  return diffDateYm(new Date(), getNewDate(item.vmanage_shop.start_date)) == 1;
}
export function isShopVManageInUse(item) {
  if (isShopVManageNoUse(item)) return false;
  if (item.vmanage_shop.scheduled_to_leave != ShopVManageScheduledToLeave.FALSE) return false
  return diffDateYm(new Date(), getNewDate(item.vmanage_shop.start_date)) != 1;
}
export function isShopVManageInUseScheduledToEnd(item) {
  if (isShopVManageNoUse(item)) return false;
  if (item.vmanage_shop.scheduled_to_leave != ShopVManageScheduledToLeave.TRUE) return false
  return diffDateYm(new Date(), getNewDate(item.vmanage_shop.end_date)) != -1;
}
export function isShopVManageEndOfUse(item) {
  if (isShopVManageNoUse(item)) return false;
  if (item.vmanage_shop.scheduled_to_leave != ShopVManageScheduledToLeave.TRUE) return false
  return diffDateYm(new Date(), getNewDate(item.vmanage_shop.end_date)) == -1;
}
export function getShopVManageStart(item) {
  if (isShopVManageWaitingToStart(item) || isShopVManageInUse(item) || isShopVManageInUseScheduledToEnd(item)) {
    return formatDateStrByDBTime(item.vmanage_shop.start_date, "YYYY-MM")
  }
}
export function getShopVManageEnd(item) {
  if (isShopVManageInUseScheduledToEnd(item) || isShopVManageEndOfUse(item)) {
    return formatDateStrByDBTime(item.vmanage_shop.end_date, "YYYY-MM")
  }
}
export function isVManageUsingShop(contain_preuse = true) {
  const shop = storeAuth.storeGetSelectedShopInfo()
  if (shop && shop.vmanage) {
    const vmanage = { vmanage_shop: shop.vmanage }
    if (contain_preuse) {
      return isShopVManageInUse(vmanage) || isShopVManageInUseScheduledToEnd(vmanage) || isShopVManageWaitingToStart(vmanage)
    } else {
      return isShopVManageInUse(vmanage) || isShopVManageInUseScheduledToEnd(vmanage)
    }
  }
  return false
}

// 数値入力補助
export function blurNumberInput(val, min, max) {
  if (val === "") return ""
  if (val || val == 0) {
    const value = new String(
      numberFloor(val, min != null ? min.split(".")[1].length * 10 : 0)
    ).toString();
    if (isNaN(value) || !value) {
      return ""
    } if (max != null && Number(value) > Number(max)) {
      return max;
    } else if (min != null && Number(value) < Number(min)) {
      return min;
    } else {
      return value;
    }
  }
  return ""
}

// 対象リスト項目取得
export function getTargetListItem(items, column) {
  let list = [];
  items.forEach((item) => {
    list.push(item[column]);
  });
  return list;
}

// ロゴ
export function getLogoFPortal() {
  if (isEnvStaging()) {
    return require("@/assets/logo_fportal_stg.png")
  } else if (isEnvProd()) {
    return require("@/assets/logo_fportal.png")
  } else if (isEnvRev()) {
    return require("@/assets/logo_fportal_rev.png")
  } else {
    return require("@/assets/logo_fportal_dev.png")
  }
}
export function getLogoVManage() {
  if (isEnvStaging()) {
    return require("@/assets/logo_timemng_stg.png")
  } else if (isEnvProd()) {
    return require("@/assets/logo_timemng.png")
  } else if (isEnvRev()) {
    return require("@/assets/logo_timemng_rev.png")
  } else {
    return require("@/assets/logo_timemng_dev.png")
  }
}

// PDFプレビューURL or Src
export async function getPdfPreviewUrlOrSrc(file) {
  if (isSystemAndroidApp()) {
    // Androidアプリの場合、Googleドキュメントビューワー（encode方式）で表示
    const pdf_url = await apiFileGetPresignedUrl(file.file_id, file.shop_id);
    return "https://docs.google.com/viewer?url=" + encodeURIComponent(pdf_url) + "&embedded=true";
  } else if (file.read_attached) {
    return getAttachedPdfSrc(file.read_attached.data);
  }
}

// 添付ファイルチェック
export function checkAttachedFiles(attached_files, checks = { limit: true }) {
  if (!attached_files) return
  if (checks.limit) {
    // 上限チェック
    let size = 0
    attached_files.forEach(v => {
      if (!v.file_id) {
        if (v.size) size += v.size
        else if (v.upload) size += v.upload.size
        else if (v.file) size += v.file.size
      }
    })
    if (size >= common.attached_files.limit_size) {
      return AttachedFilesLimitOverErrorMessage
    }
  }
  return ""
}

// URL形式チェック
export function validUrlLink(urlValue) {
  try {
    new URL(urlValue);
    return true;
  } catch (err) {
    return false;
  }
}

// 未読チャット件数取得
export async function reloadChatUnreadCount(){
  await getChatUnreadCount(true)
}
export async function getChatUnreadCount(force_reload = false) {
  let shop_id = null
  let reload_limit_at = null;
  let info = storeAuth.storeGetUnreadChat() ?? {};
  if (isShopShareMode() || isUserShopAccount()) {
    shop_id = storeAuth.storeGetKeyAsSelectedShop()
  }
  if (info.last_reload_at) {
    reload_limit_at = new Date(info.last_reload_at)
    reload_limit_at.setSeconds(reload_limit_at.getSeconds() + common.chat_message_reload_sec)
  }
  // 再取得
  if (force_reload ||
    info.associate_id != storeAuth.storeGetKeyAsSelectedAssociate() ||
    info.shop_id != shop_id ||
    !reload_limit_at ||
    new Date().getTime() > reload_limit_at.getTime()) {
    info.associate_id = storeAuth.storeGetKeyAsSelectedAssociate();
    info.shop_id = shop_id
    try {
      const count = await apiChatGetUnreadMessage(shop_id)
      info.count = count.unread
      info.last_reload_at = new Date();
    } catch (err) {
      warnLog(getRouteName(), "getChatUnreadCount", err)
      info.count = 0
      info.last_reload_at = null;
    }
    storeAuth.storeSetUnreadChat(info);
  }
  return info.count
}
export function getChatUnreadCountByStore() {
  let shop_id = isShopShareMode() || isUserShopAccount() ? storeAuth.storeGetKeyAsSelectedShop() : null
  let info = storeAuth.storeGetUnreadChat() ?? {};
  if (info.associate_id != storeAuth.storeGetKeyAsSelectedAssociate() || info.shop_id != shop_id) return 0;
  return info.count;
}

export default {
  data: () => ({
    app_upload_callback: null,
  }),
  setServiceBaseTitle,
  getHostURL,
  getTitle,
  getRoutePolicy,
  unixtimeToDate,
  unixtimeToStr,
  dateToUnixtime,
  isExpiredUnixtime,
  getNewDate,
  formatDateStrByDBTime,
  formatDateStrByUnixtime,
  formatDateStr,
  formatDateForSendApiYmd,
  diffDateYmd,
  diffDateYm,
  getDateByMonthLast,
  getDateByWeekFirst,
  getDateByWeekLast,
  getFormValues,
  copyJson,
  isStrNull,
  strIncludes,
  inputLimitCheck,
  getDatetimeLocalFormat,
  getDatetimeUTCFormat,
  generateRandamString,
  dynamoDatetimeToStr,
  getArrayColumn,
  getArrayJoinColumn,
  dataSort,
  arrayConcat,
  generateUUID_V4,
  autoLink,
  ellipsisText,
  cryptoAES,
  decryptoAES,
  getCurrentRoutePath,
  reloadPage,
  openExternalWindow,
  getAttachedFileIcon,
  getFileName,
  getExp,
  isImageType,
  isFileExp,
  isFileVideo,
  getMimeType,
  getEnabledAcceptType,
  getEnabledAcceptTypeImage,
  getImgSize,
  previewAttachedWithShareFile,
  attachedFileDownload,
  getAttachedImageSrc,
  getAttachedFileName,
  getAttachedPdfSrc,
  getUserAgent,
  isUserAgentPhone,
  isUserAgentTablet,
  isUserAgentAny,
  isSystemApp,
  isSystemWeb,
  getSystemType,
  isSystemAndroidApp,
  isSystemiOSApp,
  isDisplayModeSP,
  isDisplayModeTAB,
  isDisplayModePC,
  getMobileBreakpoint,
  browserSiteUrl,
  iOSAppUrl,
  androidAppUrl,
  pageMove,

  // ルーティング情報
  getRoute,
  getRouteName,
  getRoutePath,
  getRouter,
  routerPush,

  generateInvitationQRData,
  getCommonListGender,
  getCompanyFormList,
  getCompanyFormName,
  getCompanyFormKana,
  getCompanyFullName,
  getCompanyFullKana,
  getCompanyCorporateTypes,
  getCompanyCorporateTypeText,
  getEmployeePermanentList,
  isShopHead,
  getDeleteExecFlg,
  getApiStatusCodeInvalid,
  numberRound,
  numberCeil,
  numberFloor,
  numberPow,
  redirectNotFound,
  redirectMaintenancePage,
  readyAppUploadPhoto,
  readyAppUploadImage,
  readyAppUploadFile,
  execFinishAppUpload,
  compressUploadImage,
  restrictionCompressImage,
  startExecute,
  finishExecute,
  startExecuteLinear,
  setProgress,
  isNewDesignMode,
  getOSType,
  isWindowsOS,
  isiOS,
  getShopTimesWeekdays,
  getShopTimesWeekdayText,
  getDisplayShopTime,
  formatShopTimes,
  getFranchiseInfo,
  isFranchiseShop,
  isFranchiserShop,
  isFranchiseeShop,
  isFranchiseHQShop,
  getBrandCompanyName,
  getBrandOperationCompanyName,
  waitSleep,
  waitSleepMsec,
  getUploadErrorMessage,
  existShopVManageItem,
  isShopUseVManage,
  isShopEditableVManage,
  isShopVManageNoUse,
  isShopVManageWaitingToStart,
  isShopVManageInUse,
  isShopVManageInUseScheduledToEnd,
  isShopVManageEndOfUse,
  getShopVManageStart,
  getShopVManageEnd,
  isVManageUsingShop,
  blurNumberInput,
  getTargetListItem,
  getLogoFPortal,
  getLogoVManage,
  getPdfPreviewUrlOrSrc,
  checkAttachedFiles,
  validUrlLink,
  reloadChatUnreadCount,
  getChatUnreadCount,
  getChatUnreadCountByStore,
}

// エラーメッセージ
const AttachedFilesLimitOverErrorMessage = "ファイル容量が大きいため、ファイルを送信できませんでした。"

// 拡張子・MIMEタイプ定義
const ImageTypes = [
  { exp: 'gif', mimetype: 'image/gif' },
  { exp: 'jpeg', mimetype: 'image/jpeg' },
  { exp: 'jpg', mimetype: 'image/jpeg' },
  { exp: 'png', mimetype: 'image/png' },
  // { exp: 'heif', mimetype: 'image/heif' },
  // { exp: 'heic', mimetype: 'image/heic' },
]
const FileTypes = [
  { exp: 'text', mimetype: 'text/plain' },
  { exp: 'tsv', mimetype: 'text/tab-separated-values' },
  { exp: 'csv', mimetype: ['application/vnd.ms-excel', 'text/csv'] },
  { exp: 'pdf', mimetype: 'application/pdf' },
]
const ImageMovies = [
  { exp: 'mp4', mimetype: 'video/mp4' },
  // { exp: 'mpg', mimetype: 'video/mpeg' },
  // { exp: 'mpeg', mimetype: 'video/mpeg' },
  // { exp: 'webm', mimetype: 'video/webm' },
  { exp: 'mov', mimetype: 'video/quicktime' },
  // { exp: 'qt', mimetype: 'video/quicktime' },
  // { exp: 'ogv', mimetype: 'video/ogg' },
  // { exp: 'avi', mimetype: 'video/x-msvideo' },
]

// 店舗営業時間
const ShopTimesWeekdays = [
  { value: "1", text: "月曜" },
  { value: "2", text: "火曜" },
  { value: "3", text: "水曜" },
  { value: "4", text: "木曜" },
  { value: "5", text: "金曜" },
  { value: "6", text: "土曜" },
  { value: "0", text: "日曜" },
  // { value: "8", text: "休前日" },
  { value: "9", text: "祝日" },
]

const ShopVManageScheduledToLeave = {
  TRUE: 0,
  FALSE: 1,
}

const listGender = [
  { value: "未入力", text: "未入力" },
  { value: "男性", text: "男性" },
  { value: "女性", text: "女性" },
  { value: "その他", text: "その他" },
]


const listCompanyForm = [
  { value: "0", text: "株式会社", kana: "カブシキガイシャ" },
  { value: "1", text: "有限会社", kana: "ユウゲンガイシャ" },
  { value: "2", text: "合名会社", kana: "ゴウメイガイシャ" },
  { value: "3", text: "合資会社", kana: "ゴウシガイシャ" },
  { value: "4", text: "合同会社", kana: "ゴウドウガイシャ" },
  { value: "9", text: "個人事業主", kana: "" },
]

const listCorporateType = [
  { value: "1", text: "法人" },
  { value: "2", text: "個人事業主" },
]

const listEmployeePermanent = [
  { value: "0", text: "社員" },
  { value: "1", text: "アルバイト" },
]
