<template>
  <div class="modal-overlay">
    <div class="modal-content" v-if="draw > 0">
      <div class="modal-head">
        <h2 class="p-relative" :class="{ 'company-multiple': associates.length > 1 }" @click="clickOpenCompanySelect()">
          <span class="mr-2">{{ getSelectedCompanyName() }}</span>
          <v-icon color="primary" v-if="associates.length > 1">mdi-chevron-down</v-icon>
          <!-- 企業の選択肢 -->
          <div v-if="isOpenCompanySelect" class="menu-drawer shop-change-company-menu-drawer">
            <div v-for="(v, i) in associates" :key="i" class="menu-drawer-item"
              :class="{ 'is-selected-company': isSelectedCompany(v) }" @click.stop="changeSelectCompany(v)">
              <span>{{ getCompanyFullName(v, false) }}</span>
              <v-icon v-if="isSelectedCompany(v)" color="primary" size="large">
                mdi-checkbox-marked-circle
              </v-icon>
            </div>
          </div>
        </h2>
        <div class="simple-input" v-if="isExistHead()">
          <label for="本部" class="d-flex align-center pa-2">
            <span class="mr-2">本部</span>
            <input type="radio" id="本部" :value="true" v-model="is_selected_head" @click="selectHeadShop()" />
          </label>
        </div>
      </div>

      <template v-for="(associate, i) in associates">
        <div class="modal-body" v-if="isSelectedCompany(associate)" :key="i">
          <div class="modal-tab-head">
            <ul>
              <li v-for="(group, i) in associate.groups" :key="i" :class="{
                active: group.is_active,
              }" @click="changeGroup(associate, group)">
                <span>{{ group.name }}</span>
              </li>
            </ul>
          </div>
          <div class="modal-tab-body">
            <div class="py-6">
              <div class="modal-free-word-search">
                <input type="text" placeholder="フリーワード検索" v-model="search_text"
                  @keypress.enter="filter_text = search_text" />
                <button @click.stop="filter_text = search_text">検索</button>
              </div>
              <div class="radiotype-table">
                <table>
                  <tbody>
                    <template v-for="item in getActiveItems(associate)" :key="item.key">
                      <tr v-show="isViewShop(item)" :class="{ 'is-selected': item.key == selected_key }">
                        <td class="radiotype-name">
                          <label :for="'shop_' + item.key"></label>
                          {{ item.name }}
                        </td>
                        <td class="radiotype-name font-weight-regular">
                          <label :for="'shop_' + item.key"></label>{{ item.role_name }}
                        </td>
                        <td class="radiotype-radio">
                          <input type="radio" :id="'shop_' + item.key" :label="item.name" :value="item.key"
                            v-model="selected_key" @click="changeItem(item)" />
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="modal-bottom">
        <div class="modal-bottom-inner">
          <button class="cancel-outlined" @click="cancel()">キャンセル</button>
          <div class="save">
            <button :class="{ disabled: disabled }" @click="change()">
              切り替え
            </button>
          </div>
        </div>
      </div>
    </div>
    <ExecutingOverlay ref="execute" />
  </div>
</template>

<script>
import ExecutingOverlay from "@/components/common/ExecutingOverlay";
export default {
  props: { close: Function },
  components: { ExecutingOverlay },
  setup() {
    const { reloadNotificationStats } = require('@/helpers/common/notifications')
    const { resetSelectedShopInfo } = require('@/helpers/auth/auth_util')
    const { getChangeShopBasePage, reloadTimemngMenuChecksheets } = require('@/helpers/timemng/timemng_util')
    const { pageMove, reloadPage, getCompanyFullName, startExecute,
      getCurrentRoutePath, isVManageUsingShop, isShopEditableVManage } = require('@/helpers/common/common_util')
    const { getRoleNameByRoles } = require('@/helpers/common/role')
    const { isServiceTimemng } = require("@/helpers/environment")
    const { reloadCompanySpec } = require('@/helpers/auth/spec')
    const { storeGetKeyAsSelectedAssociate,
      storeGetKeyAsSelectedStaff,
      storeGetKeyAsSelectedShop,
      storeGetKeyAsSelectedBrand,
      storeGetAssociates,
      storeSetAccountSelectedShop } = require("@/helpers/store/storeAuthHelper")
    return {
      reloadNotificationStats,
      resetSelectedShopInfo,
      getChangeShopBasePage, reloadTimemngMenuChecksheets,
      pageMove, reloadPage, getCompanyFullName, startExecute,
      getCurrentRoutePath, isVManageUsingShop, isShopEditableVManage,
      getRoleNameByRoles,
      isServiceTimemng,
      reloadCompanySpec,

      storeGetKeyAsSelectedAssociate,
      storeGetKeyAsSelectedStaff,
      storeGetKeyAsSelectedShop,
      storeGetKeyAsSelectedBrand,
      storeGetAssociates,
      storeSetAccountSelectedShop,
    }
  },
  data: () => ({
    draw: 1,

    now_key: null,
    selected_key: null,
    selected_item: null,
    disabled: false,

    search_text: "",
    filter_text: "",

    selected_associate: null,
    associates: [],
    is_selected_head: false,
    selected_head: null,

    isOpenCompanySelect: false,
    selected_associate_work: null,
  }),
  created() {
    this.initBrands();
  },
  methods: {
    initBrands() {
      const self = this;
      const now_key = self.keyJoin([
        this.storeGetKeyAsSelectedAssociate(),
        this.storeGetKeyAsSelectedStaff(),
        this.storeGetKeyAsSelectedShop(),
        this.storeGetKeyAsSelectedBrand(),
      ]);
      self.now_key = now_key;
      self.selected_key = now_key;

      // 企業・ブランド・店舗取得
      const associates = self.storeGetAssociates();
      Object.keys(associates).forEach((key) => {
        this.associates.push(associates[key])
      })
      this.associates.sort(function (a, b) {
        const af = self.getCompanyFullName(a);
        const bf = self.getCompanyFullName(b);
        if (af > bf) return 1;
        if (af < bf) return -1;
        return 0;
      });
      this.associates.forEach((associate) => {
        const associate_id = associate.associate_id
        let groups = []
        Object.keys(associate.brands).forEach((brand_id) => {
          const brand = associate.brands[brand_id];
          let group = groups.find((v) => v.key == brand_id);
          if (!group) {
            group = {
              key: brand_id,
              name: brand.name,
              items: [],
              is_active: false,
            };
            groups.push(group);
          }
          brand.shops.forEach((shop) => {
            if (this.isServiceTimemng()) {
              const shop_info = associate.staff[shop.staff_id].shop_info
              if (!this.isShopEditableVManage({ vmanage_shop: shop_info.vmanage })) return
            }
            const key = self.keyJoin([
              associate_id,
              shop.staff_id,
              shop.shop_id,
              brand_id,
            ]);
            group.items.push({
              key: key,
              company_group_id: associate.company_group_id,
              company_id: associate.company_id,
              associate_id: associate_id,
              brand_id: brand_id,
              staff_id: shop.staff_id,
              shop_id: shop.shop_id,
              name: shop.shop_name,
              role_name: this.getRoleNameByRoles(associate.staff[shop.staff_id].roles),
            });
          });
        });
        // 店舗のないブランドは除外
        groups = groups.filter(v => v.items.length > 0)
        groups.sort(function (a, b) {
          const af = a.name;
          const bf = b.name;
          if (af > bf) return 1;
          if (af < bf) return -1;
          return 0;
        });
        groups.forEach(group => {
          group.items.sort(function (a, b) {
            const af = a.name;
            const bf = b.name;
            if (af > bf) return 1;
            if (af < bf) return -1;
            return 0;
          });
        });
        associate.groups = groups

        // 本部存在時
        if (associate.head_shop) {
          const key = self.keyJoin([
            associate_id,
            associate.head_shop.staff_id,
            associate.head_shop.shop_id,
            "",
          ]);
          let head_staff = associate.staff[associate.head_shop.staff_id]
          associate.head = {
            key: key,
            associate_id: associate_id,
            staff_id: associate.head_shop.staff_id,
            shop_id: associate.head_shop.shop_id,
            company_group_id: associate.company_group_id,
            company_id: associate.company_id,
            name: associate.company_name,
            role_name: this.getRoleNameByRoles(head_staff.roles),
          }
        }
      });

      // 選択中店舗判定
      for (let associate of this.associates) {
        let is_active_associate = false
        for (let group of associate.groups) {
          for (let item of group.items) {
            if (self.selected_key == item.key) {
              self.selected_associate = associate
              group.is_active = true;
              this.changeItem(item)
              is_active_associate = true
              break;
            }
          }
        }
        if (associate.head) {
          if (self.selected_key == associate.head.key) {
            self.selected_associate = associate
            this.selectHeadShop()
            is_active_associate = true
            if (associate.groups.length > 0) associate.groups[0].is_active = true
          }
        }
        // 未選択企業のグループ初期位置設定
        if (!is_active_associate && associate.groups.length > 0) {
          associate.groups[0].is_active = true
        }
      }

      // 選択店舗が見つからない場合の仮対処
      if (!this.selected_associate) {
        this.selected_associate = this.associates[0]
        if (this.selected_associate.groups.length > 0) this.selected_associate.groups[0].is_active = true
      }
    },

    clickOpenCompanySelect() {
      if (this.associates.length > 1) {
        this.selected_associate_work = this.selected_associate
        this.isOpenCompanySelect = !this.isOpenCompanySelect
      }
    },
    isSelectedCompany(associate) {
      return this.selected_associate && this.selected_associate.associate_id == associate.associate_id
    },
    changeSelectCompany(associate) {
      if (!this.isSelectedCompany(associate)) {
        this.selected_associate = associate
        if (this.selected_head) {
          this.is_selected_head = this.selected_associate.head == this.selected_head
        }
      }
      this.isOpenCompanySelect = false
    },
    getSelectedCompanyName() {
      if (this.selected_associate) {
        return this.getCompanyFullName(this.selected_associate, false)
      }
    },
    getSelectableGroups() {
      return this.selected_associate ? this.selected_associate.groups : []
    },
    isExistHead() {
      return this.selected_associate && this.selected_associate.head ? true : false
    },
    isSelectedHead() {
      return this.selected_associate && this.selected_associate.head == this.selected_head
    },
    selectHeadShop() {
      this.is_selected_head = true
      this.selected_head = this.selected_associate.head
      this.selected_key = this.selected_associate.head.key
      this.selected_item = null;
    },
    getActiveItems(associate) {
      const group = associate.groups.find(v => v.is_active)
      return group ? group.items : []
    },
    changeGroup(associate, group) {
      associate.groups.map(g => g.is_active = g == group)
      this.draw++
    },
    changeItem(item) {
      this.is_selected_head = false
      this.selected_head = null
      this.selected_item = item;
      this.selected_key = item.key
    },
    keyJoin(items) {
      return items.join(":::");
    },

    isViewShop(item) {
      if (this.filter_text) {
        if (!item.name.includes(this.filter_text)) return false
      }
      return true
    },

    // 店舗変更
    async change() {
      const self = this;
      if (self.disabled) {
        return;
      }
      self.disabled = true;
      if (self.now_key == self.selected_key) {
        self.close();
        return;
      }
      const before_associate = self.storeGetKeyAsSelectedAssociate()
      const item = this.selected_head ?? this.selected_item;
      self.storeSetAccountSelectedShop(
        item.associate_id,
        item.staff_id,
        item.shop_id,
        item.company_group_id,
        item.company_id,
        item.franchise_owner ? item.franchise_owner.brand_id : item.brand_id,
        item.franchise_owner
      );
      this.startExecute(this);
      await self.resetSelectedShopInfo();

      // 企業変更時は企業設定再読み込み
      if (before_associate != item.associate_id) {
        await this.reloadCompanySpec()
      }

      // お知らせ情報読み込み
      await this.reloadNotificationStats()

      // メニュー表示チェックシート読み込み
      if (this.reloadTimemngMenuChecksheets) await this.reloadTimemngMenuChecksheets(this.selected_head)

      let page = self.getChangeShopBasePage()
      if (this.isServiceTimemng()) {
        if (this.selected_head) page = "/"
        // else if (!this.isVManageUsingShop(false)) page = "/myshop/calendar"
        else page = "/todaytask"
      }
      if (self.getCurrentRoutePath() == page) {
        self.reloadPage();
      } else {
        self.pageMove(page);
      }
    },
    cancel() {
      if (!self.disabled) {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.radiotype-table table {
  table-layout: fixed;
}

.modal-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}

.modal-head h2 {
  padding: 8px;
  border-radius: 8px;
}

.modal-head h2.company-multiple:hover {
  background-color: #f8f5f5;
  cursor: pointer;
}

.modal-head .basic-table-menu-toggle:hover {
  background-color: transparent;
}
.shop-change-company-menu-drawer.menu-drawer {
  padding: 0;
  right: unset;
}

.shop-change-company-menu-drawer .menu-drawer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  padding: 12px 16px;
  text-align: left;
  white-space: nowrap;
}

.shop-change-company-menu-drawer .menu-drawer-item:hover {
  cursor: pointer;
  background-color: #f8f5f5;
}

.shop-change-company-menu-drawer .menu-drawer-item.is-selected-company {
  font-weight: bold;
}

.shop-change-company-menu-drawer .menu-drawer-item:not(:first-child) {
  border-top: 1px solid #ccc;
}

.radiotype-table tr.is-selected {
  background-color: #f8f5f5;
}
</style>