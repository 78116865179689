import { createRouter, createWebHistory } from 'vue-router'
import axios from "axios";
import common_config from "@/configs/common";
import auth_config from "@/configs/auth";
import timemng_config from "@/configs/timemanagement"
import RouteAuth from '@/router/route/auth/'
import RouteCommon from '@/router/route/common/'
import RouteTimemng from '@/router/route/timemng/'
import RouteTest from '@/router/route/test/'
import Error from '@/components/error/notfound/IndexErrorNotFound';

const routes =
  [
    ...RouteAuth,
    ...RouteCommon,
    ...RouteTimemng,
    ...RouteTest,
    { path: '/:catchAll(.*)', component: Error }
  ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
})

router.beforeEach(async (to) => {
  const storeAuth = require('@/helpers/store/storeAuthHelper')
  const storeActivate = require('@/helpers/store/storeActivateHelper')
  const storeSystem = require('@/helpers/store/storeSystemHelper')
  const { isEnvProd } = require('@/helpers/environment')
  const { isPaidMembership, isUserHeadShop } = require('@/helpers/auth/auth_util')
  const { getNewDate } = require('@/helpers/common/common_util')
  const { debugLog } = require("@/helpers/common/datadog");

  storeSystem.storeSetRoute(to)
  storeSystem.storeSetRouter(router)

  // テストページは本番利用不可
  if (!to.path.indexOf("/test/") && isEnvProd()) {
    window.location = "/notfound"
    return false
  }
  if (!to.path.indexOf("/apptest/") && isEnvProd()) {
    window.location = "/notfound"
    return false
  }

  // 認証チェック不可ページ
  if (!to.path.indexOf("/redirect/")) return
  if (!to.path.indexOf("/receive")) return
  if (!to.path.indexOf("/notfound")) return

  // ソースバージョンチェック
  const local_ver = common_config.source_version;
  const res = await axios.create({ "Cache-Control": "no-store" }).get("/source_version")
  if (res.status == 200) {
    const new_version = res.data
    if (new_version != local_ver) {
      // サーバのソースバージョンが更新されている場合は、リロードしてソース再読込
      window.location.assign(to.fullPath)
      return
    }
  }

  // 認証チェック不可ページ
  if (!to.path.indexOf("/auth/")) return

  // 招待コード一時保存
  if (to.query.ivc != undefined) {
    if (to.query.qr != undefined) {
      if (window.confirm("アプリを起動しますか？")) {
        // TODO:現状ちゃんと動かない
        let url = auth_config.application + "://"
        window.location = url
        return false;
      } else {
        storeActivate.storeSetActivateInviteCode(to.query.ivc)
      }
    } else {
      storeActivate.storeSetActivateInviteCode(to.query.ivc)
    }
  }

  // 外部認証ログイン時
  if (
    to.query.code != undefined &&
    to.query.state != undefined
  ) {
    window.location = "/auth/external/confirm?code=" + to.query.code + "&state=" + to.query.state
    return false;
  }

  // 外部認証ログインエラー時
  if (
    to.query.error_description != undefined &&
    to.query.state != undefined &&
    to.query.error != undefined
  ) {
    window.location = "/auth/external/confirm?error_description=" + to.query.error_description + "&state=" + to.query.state + "&error=" + to.query.error
    return false;
  }

  // アプリサインイン時  
  if (to.query.token != undefined) {
    window.location = "/auth/savetoken?token=" + to.query.token + "&sys=" + to.query.sys
    return false;
  }

  // ログイン中
  if (storeAuth.storeIsExistIdToken()) {

    // エラー系以外ならチェック処理
    if (to.path.indexOf("/notfound") && to.path.indexOf("/noshop") && to.path.indexOf("/iplimited")) {

      // 特定ページ以外は店舗IPアドレス利用制限
      if (to.path.indexOf("/notification") && to.path.indexOf("/chat") && to.path.indexOf("/help") && to.path.indexOf("/menus")) {
        if (!isUserHeadShop() && storeAuth.storeGetShopIpAddressLimited()) {
          window.location = "/iplimited"
          return false;
        }
      }

      // プロファイル再同期
      const now = new Date()
      const last_sync = storeAuth.storeGetLastProfileStatusSync()
      if (last_sync) {
        const resync_date = getNewDate(last_sync)
        resync_date.setSeconds(resync_date.getSeconds() + auth_config.profile_resync_sec)
        debugLog("is_profile_resync:", resync_date.getTime() < now.getTime())
        if (resync_date.getTime() < now.getTime()) {
          try {
            const res = await axios.post(
              auth_config.api.baseurl + auth_config.api.version + "/account/profile/status",
              { id_token: storeAuth.storeGetIdToken() })
            if (res.status == 200 && res.data.results.updated_at) {
              if (getNewDate(res.data.results.updated_at).getTime() > getNewDate(storeAuth.storeGetUserSync()).getTime()) {
                debugLog("!!! profile resync: updated_at=", res.data.results.updated_at)
                storeAuth.storeSetReloadRedirect(to.path)
                window.location = "/auth/getuserinfo"
                return false
              }
            }
            storeAuth.storeSetLastProfileStatusSync(now.toLocaleString({ timeZone: 'Asia/Tokyo' }))
          } catch (err) {
            // MEMO：プロファイル最新同期できない場合は無視
            debugLog(err)
          }
        }
      }

      // プロファイル状態チェック
      const errmsg = storeAuth.storeGetProfileError()
      const associate = storeAuth.storeGetSelectedAssociate()
      if (errmsg) {
        if (errmsg == 'account not found.') {
          window.location = "/auth/signout"
          return false
        } else {
          window.location = "/auth/signout"
          return false
        }
      } else if (storeAuth.storeGetAccount() && !associate) {
        window.location = "/auth/signout"
        return false
      } else if (Object.keys(associate.staff).length == 0) {
        window.location = "/auth/signout"
        return false
      }

      // タイマネ利用可否
      if (isEnvProd() && !isPaidMembership()) {
        if (window.location.host.includes("localhost")) {
          return "http://localhost:8001/"
        }
        window.location = timemng_config.portal_url;
        return false;
      }
    }
  } else {
    // 未ログイン
    window.location = "/auth/signout"
    return false
  }
})
export default router
