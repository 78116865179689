<template>
  <div class="loading-overlay" v-if="loading_overlay">
    <v-progress-circular v-if="progress_type == 'circular'" indeterminate color="#dcdede"></v-progress-circular>
    <div v-if="progress_type == 'linear'" class="loading-overlay-linear">
      <v-progress-linear v-model="progress" color="#FFB324" width="720" height="25">
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading_overlay: false,
    progress_type: "circular",
    progress: 0,
  }),
  created() {
    this.loading_overlay = false;
  },
  methods: {
    view(is_view, progress_type = "circular") {
      this.progress_type = progress_type;
      this.progress = 0;
      this.loading_overlay = is_view;
    },
    setProgress(progress) {
      this.progress = progress;
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-overlay-linear {
  width: 80%;
}
.v-progress-linear {
  background-color: #ffe1a7;
  border-color: #ffe1a7;
}
</style>