<template>
  <footer class="sp-footer-menus">
    <div v-for="(link, i) in getTimemngSpFooterLinks()" :key="i">
      <button @click="clickLink(link)" :class="{ active: isActive(link) }">
        <div class="sp-footer-icon-wrap">
          <v-icon :color="isActive(link) ? 'CF197A' : '#AFBABF'" size="24">
            {{ link.icon }}
          </v-icon>
          <!-- 通知バッヂ -->
          <span v-if="link.is_newer" class="sp-notification-badge"></span>
          <span v-if="link.name == 'チャット' && unread_count > 0" class="sp-notification-badge"></span>
        </div>
        <div class="mt-1">{{ link.name }}</div>
      </button>
    </div>
  </footer>
</template>

<script>
export default {
  props: { active: String },
  setup() {
    const { debugLog } = require('@/helpers/common/datadog')
    const { getTimemngSpFooterLinks } = require('@/helpers/timemng/timemng_util')
    const { pageMove, getChatUnreadCount } = require('@/helpers/common/common_util')
    const { isShopShareMode } = require('@/helpers/auth/auth_util')
    return {
      debugLog,
      getTimemngSpFooterLinks,
      pageMove,
      getChatUnreadCount,
      isShopShareMode,
    }
  },
  data: () => ({
    unread_count: 0,
  }),
  async created() {
    this.unread_count = await this.getChatUnreadCount()
  },
  methods: {
    clickLink(link) {
      if (!link.active) {
        this.pageMove(link);
      }
    },
    isActive(link) {
      if (this.active) {
        return this.active == link.key;
      } else {
        return link.active;
      }
    },
  },
};
</script>

<style scoped>
.sp-notification-badge {
  right: 0;
}
</style>

