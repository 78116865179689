import api_common from "@/helpers/api_common.js";

export default {

    // 一覧取得
    getOwnList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/own/list",
            query: { staff_id: staff_id, page: page },
        })
    },

    // 一覧取得
    getList(apiconf, staff_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/list",
            query: { staff_id: staff_id, page: page },
        })
    },
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "advance/shop/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 作成
    create(apiconf, staff_id, shop) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/create",
            query: { staff_id: staff_id, shop: shop },
        })
    },

    // 更新
    update(apiconf, staff_id, shop, brand, area, shop_times) {
        const query = { staff_id: staff_id, shop: shop, brand: brand, area: area }
        if (shop_times) query.shop_times = shop_times
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/update",
            query: query,
        })
    },

    // 営業時間更新
    updateTimes(apiconf, staff_id, shop_id, shop_times) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/times/update",
            query: { staff_id: staff_id, shop_id: shop_id, shop_times: shop_times },
        })
    },

    // 店舗スタッフ一覧
    getStaffList(apiconf, staff_id, shop_id, with_shop_user, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/staff/list",
            query: { staff_id: staff_id, shop_id: shop_id, with_shop_user: with_shop_user, page: page },
        })
    },

    // 店舗カレンダー取得
    getCalendar(apiconf, staff_id, shop_id, spec_date) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/calendar",
            query: { staff_id: staff_id, shop_id: shop_id, spec_date: spec_date },
        })
    },
    getCalendars(apiconf, staff_id, shop_id, start_date, end_date) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/calendars",
            query: { staff_id: staff_id, shop_id: shop_id, start_date: start_date, end_date: end_date },
        })
    },

    // 店舗休業一覧
    getHolidayList(apiconf, staff_id, shop_id, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/holiday/list",
            query: { staff_id: staff_id, shop_id: shop_id, page: page },
        })
    },

    // 店舗休業追加
    addHoliday(apiconf, staff_id, shop) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/event/add/holiday",
            query: { staff_id: staff_id, shop: shop },
        })
    },

    // 店舗休業削除
    removeHoliday(apiconf, staff_id, shop_holiday_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/event/rm/holiday",
            query: { staff_id: staff_id, shop_holiday_id: shop_holiday_id },
        })
    },

    // VManage利用可否
    statusVManage(apiconf, staff_id, shop_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/vmanage/status",
            query: { staff_id: staff_id, shop_id: shop_id },
        })
    },
    createVManage(apiconf, staff_id, vmanage) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/vmanage/create",
            query: { staff_id: staff_id, vmanage: vmanage },
        })
    },
    updateVManage(apiconf, staff_id, vmanage) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "shop/vmanage/update",
            query: { staff_id: staff_id, vmanage: vmanage },
        })
    },
}