import api_common from "@/helpers/api_common.js";

export default {

    // ランキング取得
    getMonthly(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "ranking/shop/monthly",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    getWeekly(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "ranking/shop/weekly",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // ブランド取得    
    getMonthlyBrand(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "ranking/brand/monthly",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    getWeeklyBrand(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "ranking/brand/weekly",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // ランキング集計除外
    getExCludeRankingList(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/from/ranking/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
    excludeRanking(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "exclude/from/ranking",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}