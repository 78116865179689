<template>
  <div id="modal">
    <div class="modal-overlay">
      <div class="modal-content permanently-delete">
        <div class="modal-body">
          <div class="modal-body-inner">
            <p class="permanently-delete-strong mt-0">
              {{ modal_title }}
            </p>
            <p v-html="modal_message"></p>
          </div>
        </div>
        <div class="modal-bottom">
          <div class="modal-bottom-inner">
            <div class="cancel">
              <button @click="close(false)">キャンセル</button>
            </div>
            <div :class="modal_closeclass">
              <button @click="close(true)">{{ modal_btntext }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    btntext: String,
    closeClass: String,
    close: Function,
  },
  data: () => ({
    modal_title: "",
    modal_message: "",
    modal_btntext: "",
    modal_closeclass: "",
  }),
  created() {
    this.modal_title = this.title ?? "削除します";
    this.modal_message = this.message ?? "削除します。よろしいですか？";
    this.modal_btntext = this.btntext ?? "削除";
    this.modal_closeclass = this.closeClass ?? "del";
  },
};
</script>
