export default {
    conf: {
        clientToken: "pub21680a45acb376bd8dc0463a7374ce4a",
        site: "datadoghq.com",
        service: {
            prod: "eatery-web-prd",
            dev: "eatery-web-dev"
        },
        version: "41",
        forwardErrorsToLogs: true,
        sampleRate: 0,
    },
    runConf: {
        applicationId: '0727c546-4fa2-44ff-ac3f-854d7cadd581',
        clientToken: 'pub364e6a9c289ecacd47318af9dcf104c2',
        site: 'datadoghq.com',
        service: {
            prod: "v-manage-prd",
            dev: "v-manage-dev"
        },
        sessionSampleRate: 0,
        trackUserInteractions: true,
        trackResources: false,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input',
        available: true,
    }
}