import api_common from "@/helpers/api_common.js";

export default {

    // 通知バーに表示する通知メッセージ一覧を取得する
    getListForBar(apiconf, staff_id, dest_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "notification/bar/tm",
            query: { staff_id: staff_id, dest_id: dest_id, },
        })
    },
}