<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import common_config from "@/configs/common";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from '@datadog/browser-rum';
import datadog from "@/configs/datadog";
export default {
  setup() {
    const { isDisplayModeSP } = require('@/helpers/common/common_util')
    const { isEnvProd, isEnvStaging, isEnvRev } = require('@/helpers/environment')
    return {
      isDisplayModeSP,
      isEnvProd,
      isEnvStaging,
      isEnvRev
    }
  },
  created() {
    this.setupFavicon()
    this.setupCss()
    this.setupDataDog()
  },
  methods: {
    setupCss() {
      // CSS
      const envcss = this.isEnvProd()
        ? common_config.css.prod
        : common_config.css.dev;
      for (const css of this.isDisplayModeSP() ? envcss.sp : envcss.pc) {
        //   rel.push({ rel: "stylesheet", href: css });
        // }

        // for (const css of isUserAgentPhone() ? envcss.sp : envcss.pc) {
        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = css;
        document.head.appendChild(style);
      }
    },
    setupFavicon() {
      // Favicon設定
      let env_favicon;
      if (this.isEnvStaging()) {
        env_favicon = common_config.favicon.stg
      } else if (this.isEnvProd()) {
        env_favicon = common_config.favicon.prod
      } else if (this.isEnvRev()) {
        env_favicon = common_config.favicon.rev
      } else {
        env_favicon = common_config.favicon.dev
      }

      const favicon = document.createElement("link");
      favicon.rel = "icon";
      favicon.href = env_favicon;
      document.head.appendChild(favicon);
    },
    setupDataDog() {
      let config = datadog.conf;
      if (config.service && !datadogLogs.getInitConfiguration()) {
        config.service = this.isEnvProd()
          ? config.service.prod
          : config.service.dev;
        datadogLogs.init(config);
      }
      let runConfig = datadog.runConf;
      if (runConfig.available && runConfig.service && !datadogRum.getInitConfiguration()) {
        runConfig.service = this.isEnvProd()
          ? runConfig.service.prod
          : runConfig.service.dev;
        datadogRum.init(runConfig);
        // datadogRum.startSessionReplayRecording();
      }
    },
  },
};
</script>

<style >
.theme--light.v-application {
  background: none !important;
}

.card-noborder {
  box-shadow: none !important;
}

.header_active {
  background-color: #cf197a !important;
  color: #fff !important;
}
</style>