import config from '@/configs/timemanagement';

export default
    [
        {
            path: config.basepath + '/dashboard',
            name: 'timemanagement-dashboard',
            component: () => import('@/components/timemanagement/dashboard/v1/DashboardBase'),
            meta: { title: 'ダッシュボード' },
        },
        {
            path: config.basepath + '/dashboard/:mode',
            name: 'timemanagement-dashboard-mode',
            component: () => import('@/components/timemanagement/dashboard/v1/DashboardBase'),
            meta: { title: 'ダッシュボード' },
        },
        {
            path: config.basepath + '/dashboard/:mode/:date',
            name: 'timemanagement-dashboard-date',
            component: () => import('@/components/timemanagement/dashboard/v1/DashboardBase'),
            meta: { title: 'ダッシュボード' },
        },

        // ダッシュボード（ベータ）
        {
            path: config.basepath + '/dashboard-beta',
            name: 'timemanagement-dashboard-beta',
            component: () => import('@/components/timemanagement/dashboard/v2/DashboardBase'),
            meta: { title: 'ダッシュボード ベータ版', policy: { is_sp: false } },
        },
    ]
