<template>
  <div class="pc-left-menu" v-if="draw > 0">
    <div v-for="(link, i) in parents.header_links" :key="i">
      <template v-if="Array.isArray(link.sublinks) && link.sublinks.length > 0">
        <div :key="'menu-container' + i" :class="'menu-container' + i">
          <v-menu location="end">
            <template v-slot:activator="{ props }">
              <v-btn
                size="large"
                variant="text"
                color="white"
                v-bind="props"
                height="auto"
                class="pc-left-menu-item fullwidth-element px-0"
                :class="link.color_class"
              >
                <div class="pc-left-menu-item-icon">
                  <v-icon color="white">
                    {{ link.icon }}
                  </v-icon>
                </div>
                <div class="pc-left-menu-item-text">{{ link.name }}</div>
              </v-btn>
            </template>
            <v-list width="240" bottom :class="'pc-left-menu-second-' + link.icon" bg-color="#333">
              <template v-for="(sublink, j) in link.sublinks">
                <v-list-item
                  v-if="!Array.isArray(sublink.thirdlinks) || sublink.thirdlinks.length === 0"
                  :key="'pc-left-menu-second-item-' + j"
                  @click="clickLink(sublink)"
                  class="pc-left-menu-second-item"
                  :class="{ active: isActiveLink(sublink) }"
                >
                  {{ sublink.name }}
                </v-list-item>

                <v-menu v-else :key="j" location="end">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" append-icon="mdi-menu-right">
                     {{ sublink.name }}
                      <!-- <v-icon></v-icon> -->
                    </v-list-item>
                  </template>
                  <v-list dark width="240" bg-color="#333">
                    <v-list-item
                      v-for="(thridlink, k) in sublink.thirdlinks"
                      :key="k"
                      @click="pageMove(thridlink)"
                      class="pc-left-menu-third-item"
                      :class="{ active: isActiveLink(thridlink) }"
                    >
                      {{ thridlink.name }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-else>
        <v-btn
          size="large"
          variant="text"
          color="white"
          height="auto"
          ripple
          @click="pageMove(link)"
          class="pc-left-menu-item fullwidth-element px-0"
          :class="link.color_class"
        >
          <div class="pc-left-menu-item-icon">
            <v-icon color="white">
              {{ link.icon }}
            </v-icon>
          </div>
          <div class="pc-left-menu-item-text">{{ link.name }}</div>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PcLeftMenu",
  props: ["parents"],
  data: () => ({
    draw: 1,
    open: false,
  }),
  setup() {
    const { pageMove, getCurrentRoutePath } = require('@/helpers/common/common_util')
    return {
      pageMove, getCurrentRoutePath,
    }
  },
  methods: {
    clickLink(link) {
      this.pageMove(link);
      this.draw++;
    },
    isActiveLink(link) {
      return this.getCurrentRoutePath() == link.to;
    },
    reset() {
      this.draw++;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-list {
  background: #333;
}
.pc-left-menu {
  background: #222;
  box-sizing: border-box;
  font-size: 14px;
  height: calc(100vh - 48px);
  min-width: 116px;
  max-width: 116px;
  padding: 8px 8px 60px 8px;
  position: sticky;
  top: 48px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  /* Firefoxスクロールバー制御 */
  z-index: 2;
}

/* Chrome, Edgeスクロールバー制御 */
.pc-left-menu::-webkit-scrollbar {
  display: none;
}

.pc-left-menu-second-mdi-playlist-check {
  max-height: 60vh;
  overflow-y: auto;
  overscroll-behavior-y: none;
}

.pc-left-menu-item {
  box-sizing: border-box;
  color: #fff;
  position: relative;
  text-align: center;
}

.pc-left-menu-item ::v-deep(.v-btn__content) {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.pc-left-menu-item ::v-deep(.v-btn__content) {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.pc-left-menu-item .v-btn {
  height: auto;
}

.pc-left-menu-item.active,
.pc-left-menu-second-item.active,
.pc-left-menu-third-item.active {
  background-color: #666;
}
.pc-left-menu-item-text {
  font-size: 12px;
  letter-spacing: 0;
}

.pc-left-menu-item-icon i {
  font-size: 32px;
}

/* エリマネ・SV用の画面で表示メニューが増えた際、使用するデバイスによって見切れてしまうため調整する */
@media screen and (max-height: 700px) {
  .pc-left-menu-second.pc-left-menu-secondmdi-playlist-check {
    max-height: 40vh;
  }
}
</style>