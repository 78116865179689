import api_common from "@/helpers/api_common.js";

export default {
    
    // お問い合わせ
    contact(apiconf, staff_id, params, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    contact: params,
                }
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "contact",
            formdata: formdata,
        })
    },
}